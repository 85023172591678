import {createStore, applyMiddleware} from "redux";
import socketMiddleware from "./middlewares/websocketMiddleware";
import dummyMiddleware from "./middlewares/dummyMiddleware";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import {composeWithDevTools} from 'redux-devtools-extension';
import settingsMiddleware from "./middlewares/settingsMiddleware";

const initialState = {};

const middleware = [socketMiddleware, dummyMiddleware, settingsMiddleware, thunk];

let store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware)),
    )
;

export default store;
