import {combineReducers} from "redux";
import dataReducer from "./dataReducer";
import appReducer from "./appReducer";
import employeeReducer from './employeeReducer';
import settingsReducer from "./settingsReducer";

const allReducers = combineReducers({
    data: dataReducer,
    app: appReducer,
    employees: employeeReducer,
    settings: settingsReducer
});
export default allReducers;