import React, {Component} from 'react';
import {connect} from "react-redux";
import {openWebsocket} from "../../../actions/actions";
import Summary from "./Summary";
import {
    NAMESPACE_RMA,
} from "../../../actions/types";

class RmaDashboard extends Component {
    componentDidMount() {
        this.connectSockets();
    }

    connectSockets = () => {
        this.props.openWebsocket(NAMESPACE_RMA);
    }

    render() {
        return (
            <div className='rma-dashboard'>
                <div id="left" className="medium-column">
                    <Summary/>
                </div>
                <div id="center" className="medium-column"/>

                <div id="right" className="medium-column"/>
            </div>
        );
    }
}


const mapStateToProps = (state = {}) => {
    if (
        typeof state.data["event-rma-per-state-count"] !== "undefined"
    ) {
        return {
            result: state.data["event-rma-per-state-count"]
        };
    }

    return {
        result: []
    };
};
export default connect(mapStateToProps, {openWebsocket})(RmaDashboard);
