// eslint-disable-next-line
import {
    OPEN_WEBSOCKET,
    TV_MODE_ON,
    TV_MODE_OFF,
    GET_NAMESPACE_SETTINGS,
    GET_NAMESPACE_SETTINGS_SUCCESS,
    SET_NAMESPACE_SETTINGS,
    SET_NAMESPACE_SETTINGS_SUCCESS
} from "./types";


export const openWebsocket = (namespace) => dispatch => {

    dispatch({
        type: OPEN_WEBSOCKET,
        namespace
    })
};

export const TvModeOn = () => {
    return {
        type: TV_MODE_ON
    };
}
export const TvModeOff = () => {
    return {
        type: TV_MODE_OFF
    };
}

export const getNamespaceSettings = (namespace) => dispatch => {
    dispatch({
        type: GET_NAMESPACE_SETTINGS,
        namespace
    })

    setInterval(function () {
            dispatch({
                type: GET_NAMESPACE_SETTINGS,
                namespace
            })
        },
        30000
    );
};

export const getNamespaceSettingsSuccess = (namespace, settings) => {
    return {
        type: GET_NAMESPACE_SETTINGS_SUCCESS,
        namespace,
        settings
    }
};

export const setNamespaceSettings = (namespace, settings) => {
    return {
        type: SET_NAMESPACE_SETTINGS,
        namespace,
        settings
    }
};

export const setNamespaceSettingsSuccess = (namespace, settings) => {
    return {
        type: SET_NAMESPACE_SETTINGS_SUCCESS,
        namespace,
        settings
    }
};