import React, { Component } from "react";
import { ResponsiveBar } from "@nivo/bar";
import messages from "../../common/Variables/WarehouseMessages";
import settingsVars from "../Variables/SettingsVariables";

/*
Expected props:
progression={n}
goal={n}

optional:
simple={true} // Removes legend
percentage={true} (displays in percentages instead of just numbers)
label='string'
 */
class Progress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxValue: 100,
      status: "bad",
      data: null,
    };
  }

  componentDidMount() {
    const { progression, goal, label, showIncredible } = this.props;
    this.setValues(progression, goal, label, showIncredible);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { progression, goal, label, showIncredible } = this.props;
      this.setValues(progression, goal, label, showIncredible);
    }
  }

  setValues = (progression, goal, label, showIncredible) => {
    const percentage = (progression / goal) * 100;
    let maxValue = 100;
    let status = "bad";

    if (percentage > maxValue) {
      maxValue = percentage;
    }
    if (percentage <= settingsVars.warehousePerformance.bad) {
      status = "bad";
    } else if (percentage <= settingsVars.warehousePerformance.okay) {
      status = "okay";
    } else if (
      percentage <= settingsVars.warehousePerformance.good ||
      !showIncredible
    ) {
      status = "good";
    } else if (percentage >= settingsVars.warehousePerformance.incredible) {
      status = "incredible";
    }

    this.setState({
      data: [
        {
          value: label,
          Progress: Math.round(percentage),
        },
      ],
      maxValue,
      status,
    });
  };

  render() {
    let { progression, goal, label, simple, showText } = this.props;
    let { maxValue, status } = this.state;
    let message = "";
    const colors = {
      bad: "#F3575E",
      okay: "#EF7102",
      good: "#12AC74",
      incredible: "#FFE3E3",
    };
    let color = "#ffffff";

    if (status === "bad") {
      const bad = messages.bad;
      message = bad[Math.floor(Math.random() * bad.length)];
      color = colors.bad;
    } else if (status === "okay") {
      const okay = messages.okay;
      message = okay[Math.floor(Math.random() * okay.length)];
      color = colors.okay;
    } else if (status === "good") {
      const good = messages.good;
      message = good[Math.floor(Math.random() * good.length)];
      color = colors.good;
    } else if (status === "incredible") {
      const incredible = messages.incredible;
      message = incredible[Math.floor(Math.random() * incredible.length)];
      color = colors.incredible;
    }
    return (
      <div className="o-progressbar__container">
        {!simple ? (
          <div>
            <div className="o-progressbar__labels">
              <div className="c-label">{label}</div>
              <div className="c-label">Doel</div>
            </div>

            <div
              className={`${status} o-progressbar__labels numbers ${
                status === "incredible" ? "rainbow-text" : null
              }`}
            >
              {showText && <div>{message}</div>}
              {!showText && <div />}
              {this.props.percentage ? (
                <div>{Math.round((progression / goal) * 100)}/100%</div>
              ) : (
                <div>
                  {progression}/{goal}
                </div>
              )}
            </div>
          </div>
        ) : null}

        <div className="o-progressbar">
          <ResponsiveBar
            data={this.state.data}
            keys={["Progress"]}
            indexBy="value"
            layout="horizontal"
            maxValue={maxValue}
            padding={0}
            colors={color}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            labelFormat={(v) => `${v}%`}
            labelTextColor="#ffffff"
            enableGridY={false}
          />
          <div className={status === "incredible" ? "rainbow" : null}>
            <div className="white">
              {" "}
              {status === "incredible"
                ? `${this.state.data[0].Progress}% `
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Progress;
