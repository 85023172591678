import React from "react";
import StarRatingComponent from "react-star-rating-component";
import Moment from "react-moment";

function Review(props) {
    return (
        <div className="s-review">
            <div className="o-review__header">
                <div className="o-review__header__title">{props.title}</div>
                <div className="o-review__header__stars">
                    <StarRatingComponent
                        name="reviewRating"
                        starCount={5}
                        value={props.rating}
                        emptyStarColor={"#f5f5f5"}
                        editing={false}
                    />
                </div>
            </div>
            <div className="o-review__body">
                <div id="review" className="o-review__body__review">
                    {props.review}
                </div>
                <div className="o-review__body__rating">{props.rating * 2}</div>
            </div>
            <div className="o-review__footer">
                <div className="o-review__footer__date">
                    <Moment format="DD-MM-YYYY" date={props.date}/>
                </div>
                <div>, {props.author}</div>
                <div>, {props.city}</div>
            </div>
        </div>
    );
}


export default Review;
