import React from 'react';
import NumberBoxes from "../common/NumberBoxes/NumberBoxes";
import PerformanceBar from "../common/Charts/PerformanceBar";
import colors from "../common/Variables/Colors";
import {getGrowth, getMonthDutch, getYear} from "../common/Functions";
import Product from "../common/Product";

function DepartmentBody(props) {
    let users = 0;
    let usersLastYear = 0;
    let conversionRatio = 0;
    let conversionRatioLastYear = 0;
    let revenueThisMonth = 0;

    let revenueThisMonthLastYear = 0;
    let revenueThisMonthLastYearTillNow = 0;
    let lastSoldItem = 0;
    if (typeof props !== "undefined") {
        users = props.dataPast30Days.users;
        usersLastYear = props.dataLastYear.users;
        conversionRatio = Math.round(props.dataPast30Days.conversie * 100) / 100;
        conversionRatioLastYear = Math.round(props.dataLastYear.conversie * 100) / 100;
        if (typeof props.dataRevenue !== 'undefined') {
            revenueThisMonth = Math.round(props.dataRevenue.revenue_this_month);
            revenueThisMonthLastYear = Math.round(props.dataRevenue.revenue_this_month_last_year);
            revenueThisMonthLastYearTillNow = Math.round(props.dataRevenue.revenue_this_month_last_year_till_now);

            lastSoldItem = props.dataRevenue.lastSoldItem;
        }
    }

    return (
        <div>
            <NumberBoxes
                value1={Number(revenueThisMonthLastYearTillNow)}
                label1={"Momentopname " + getMonthDutch() + ' ' +  (getYear()-1)}
                growth1={getGrowth(revenueThisMonth, revenueThisMonthLastYearTillNow)}
                isMoney1={true}
                value2={revenueThisMonthLastYear}
                label2={"Maandomzet " + getMonthDutch() + ' ' + (getYear()-1)}
                growth2={getGrowth(revenueThisMonth, revenueThisMonthLastYear)}
                isMoney2={true}
            />
            <PerformanceBar label={"Momentopname " +  getMonthDutch() + ' ' +  (getYear()-1)} isPercentage={true} valueNow={revenueThisMonth} valuePrevious={revenueThisMonthLastYearTillNow}
                            colors={colors.blues}/>
            <NumberBoxes value1={conversionRatio} growth1={getGrowth(conversionRatio, conversionRatioLastYear)}
                         label1='Ander leuk feitje'
                         label2='Gebruikers'
                         growth2={getGrowth(users, usersLastYear)}
                         value2={Number(users)}
            />
            {typeof  lastSoldItem !== "undefined" && lastSoldItem !== {} ? <Product name={lastSoldItem.name} price={lastSoldItem.price}
                                                                                 img={lastSoldItem.image}/>  : null}

        </div>
    );
}

export default DepartmentBody;
