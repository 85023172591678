import React from "react";
import Gauge from "../../common/Charts/Gauge";
import colors from "../../common/Variables/Colors";
import DoubleHeader from "../../common/Headers/DoubleHeader";

function New(props) {
  return (
    <>
      <div className="block">
          <DoubleHeader
              label1={"Pending"}
              number1={props.rulesNew}
              label2={"Ready"}
              number2={props.rulesReady}
              color={"blue"}
              tooltip={"Regels"}
              secondNumber1={props.shipmentsNew}
              tooptipSecondNumber1={"Zendingen"}
              secondNumber2={props.shipmentsReady}
              tooptipSecondNumber2={"Zendingen"}
          />
      </div>
      <div className="block">
        <Gauge
          topColor={colors.blues[0]}
          progression={props.rulesComplete}
          //goal={props.rulesNew + props.rulesProcessing + props.rulesComplete}
          goal={Math.round(
            props.rulesNew +
                props.rulesReady +
              props.rulesProcessing +
              props.rulesComplete +
              (props.newOrdersLastHour / 100) *
                props.ratio *
                props.hoursToCutoff *
                (props.rulesNew / props.shipmentsNew)
          )}
          label="Voortgang"
        />
      </div>
    </>
  );
}
//Aantal orders per uur * aantal uren tot cutoff * (zending regels / zendingen)
export default New;
