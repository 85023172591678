import moment from "moment";

export function getMax() {
  //This is for the customer service to see if they have a lot of work to do. This has to scale per month, in the summer it is more likely to have more RMA.
  const d = new Date();
  const n = d.getMonth() + 1;
  if (n >= 0 && n <= 2) {
    //jan-feb
    return 150;
  } else if (n >= 3 && n <= 5) {
    //mar-mei
    return 300;
  } else if (n >= 6 && n <= 8) {
    //"jun-augustus")
    return 900;
  } else if (n >= 9 && n <= 12) {
    //"sep-december"
    return 150;
  } else {
    //error
    return 2000;
  }
}

export function filterTickets(dataToFilter) {
  if (dataToFilter === [] || typeof dataToFilter === "undefined") {
    return;
  }
  let statusData = [];
  let ticketsSolvedCount = 0;
  let ticketsOpenCount = 0;
  let ticketsNewCount = 0;
  let ticketsPendingCount = 0;
  let totalTicketsToDo = 0;

  let catProduct = 0;
  let catOrder = 0;
  let catRMA = 0;
  let catComplain = 0;
  let catDiverse = 0;
  let catNone = 0;

  if (dataToFilter !== undefined) {
    dataToFilter.forEach((item) => {
      if (item.status === "open") {
        let noCatogory = false;
        let hasCatogory = false;
        if (
          typeof item.createdAt !== "undefined" &&
          typeof item.updatedAt !== "undefined"
        ) {
          if (item.createdAt.slice(0, -9) === item.updatedAt.slice(0, -9)) {
            //check if its in the last 10 minutes
            ticketsPendingCount++;
          } else if (typeof item.assignedTo !== "undefined") {
            ticketsNewCount++;
          } else {
            ticketsOpenCount++;
          }
        }

        if (typeof item.tags !== "undefined") {
          if (item.tags.length > 0) {
            item.tags.forEach((tag) => {
              if (!hasCatogory) {
                if (tag.includes("product")) {
                  catProduct++;
                  hasCatogory = true;
                } else if (tag.includes("bestelling")) {
                  catOrder++;
                  hasCatogory = true;
                } else if (tag.includes("rma")) {
                  catRMA++;
                  hasCatogory = true;
                } else if (tag.includes("klacht")) {
                  catComplain++;
                  hasCatogory = true;
                } else if (tag.includes("prijsaanvraag")) {
                  catDiverse++;
                  hasCatogory = true;
                } else {
                  noCatogory = true;
                }
              }
            });
            if (noCatogory && !hasCatogory) {
              catNone++;
            }
          } else {
            catNone++;
          }
        } else {
          catNone++;
        }
      }
    });

    statusData.push(
      { value: ticketsSolvedCount, label: "Opgelost", id: "Opgelost" },
      { value: ticketsOpenCount, label: "Open", id: "Open" },
      { value: ticketsNewCount, label: "Nieuw", id: "Nieuw" },
      {
        value: ticketsPendingCount,
        label: "In afwachting",
        id: "Inafwachting",
      }
    );
    totalTicketsToDo = ticketsOpenCount + ticketsPendingCount + ticketsNewCount;

    const data = {
      totalTicketsToDo,
      statusData: [
        {
          Werkzaamheden: "",
          Nieuw: ticketsNewCount,
          Open: ticketsOpenCount,
          "In afwachting": ticketsPendingCount,
        },
      ],
      categoryData: [
        {
          id: "product",
          label: "Product",
          value: catProduct,
        },
        {
          id: "order",
          label: "Bestelling",
          value: catOrder,
        },
        {
          id: "rma",
          label: "RMA",
          value: catRMA,
        },
        {
          id: "complaint",
          label: "Klacht",
          value: catComplain,
        },
        {
          id: "diverse",
          label: "Diverse",
          value: catDiverse,
        },
        {
          id: "none",
          label: "Niet toegewezen",
          value: catNone,
        },
      ],
      totalCustomerService: ticketsNewCount + ticketsOpenCount,
    };
    return data;
  }
}

export function setLineData(ticketArray) {
  let data = {
    open: [],
    solved: [],
    new: [],
  };
  let date = new Date();

  let currentHour = date.getHours();
  let i;
  let timeArray = [];
  for (i = 0; i < 8; i++) {
    let timeToAdd = currentHour + 1;
    if (timeToAdd <= 0) {
      timeToAdd = 24;
      currentHour = 23;
    }
    currentHour--;

    timeArray.push(timeToAdd);
  }
  timeArray.reverse();

  timeArray.forEach(function (hourLabel) {
    let dateFix = new Date();
    dateFix.setHours(12);
    let hourFix = 12 - dateFix.getUTCHours();
    let hour = ("0" + (parseInt(hourLabel) - hourFix)).slice(-2);
    let date = new Date();
    let currentHour = ("0" + (date.getHours() - hourFix)).slice(-2);
    let countOpen = 0;
    let countSolved = 0;
    let countNew = 0;
    let lastHour = moment()
      .utc()
      .format(
        `YYYY-MM-DDT${("0" + (parseInt(hourLabel) - (hourFix + 1))).slice(
          -2
        )}:00:00`
      );
    let thisHour = moment()
      .utc()
      .format(
        `YYYY-MM-DDT${("0" + (parseInt(hourLabel) - hourFix)).slice(-2)}:00:00`
      );
    thisHour += "Z";
    lastHour += "Z";

    ticketArray.filter(function (item) {
      if (item.createdAt >= lastHour && item.createdAt <= thisHour) {
        countNew++;
      }
      if (item.status === "closed" && item.updatedAt <= thisHour) {
        return countSolved++;
      }
      if (item.status !== "closed" && item.createdAt <= thisHour) {
        return countOpen++;
      }

      if (item.updatedAt >= lastHour && item.createdAt <= thisHour) {
        return countOpen++;
      }
      return null;
    });
    if (hour <= currentHour + 1) {
      data.solved.push({ x: hourLabel + ":00", y: countSolved });
      data.open.push({ x: hourLabel + ":00", y: countOpen });
      data.new.push({ x: hourLabel + ":00", y: countNew });
    }
  });

  return [
    {
      id: "Openstaand",
      color: "hsl(357, 87%, 65%)",
      data: data.open,
    },
    {
      id: "Opgelost",
      color: "hsl(158, 81%, 37%)",
      data: data.solved,
    },
    {
      id: "Nieuw",
      color: "hsl(47.7, 100%, 50.2%)",
      data: data.new,
    },
  ];
}
