import React, {Component} from "react";
import {Link} from "react-router-dom";
import Warehouse from "../img/warehouse.png";
import CustomerService from "../img/customer-service.png";
import Content from "../img/content.png";

export default class Choose extends Component {
    render() {
        return (

            <div className="s-choose-dashboard">
                <div className="s-choose-dashboard__title">
                    Kies een dashboard
                </div>

                <div className="o-dashboard-cards">
                    <div className="o-dashboard-card">
                        <Link to="/customer-service">
                            <div className="o-dashboard-card__img">
                                <img
                                    src={CustomerService}
                                    alt="Customer service dashboard"
                                />
                            </div>

                            <div className="o-dashboard-card__text">
                                Klantenservice
                            </div>
                        </Link>
                    </div>
                    <div className="o-dashboard-card">
                        <Link to="/content">
                            <div className="o-dashboard-card__img">
                                <img
                                    src={Content}
                                    alt="Content and marketing dashboard"
                                />
                            </div>

                            <div className="o-dashboard-card__text">
                                Content & Marketing
                            </div>
                        </Link>
                    </div>
                    <div className="o-dashboard-card">
                        <Link to="/warehouse">
                            <div className="o-dashboard-card__img">
                                <img
                                    src={Warehouse}
                                    alt="Magazijn - Pakket"
                                />
                            </div>

                            <div className="o-dashboard-card__text">
                                Magazijn
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

        )
            ;
    }
}
