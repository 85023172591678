import React from "react";
import Clock from "react-live-clock";

function InfoBars() {
  return (
    <div className="s-infobars">
      <div className="block__no_margin clock">
        <Clock format={"HH:mm:ss"} ticking={true} />
      </div>
    </div>
  );
}

export default InfoBars;
