import React from 'react';
import HorizontalLegendItem from "./HorizontalLegendItem";
import {getColorFromColorArray} from '../../Functions'

/*
Expected props:
items={['item1', 'item2']} //Items, recommended to get these items with getKeys() function from ../common/Functions and remove the first item from the array.
colors={["#ffffff", "#fffff1"]} // Recommended to get these colors from ../common/Variables/colors and use color.yellows for example.


Example usage:
  <HorizontalLegend items={['New', 'Processing']} colors={["#289BF6", "#3CB4FF"]}/>
 */

function HorizontalLegend(props) {
    let colorCount = 0;
    return (
        <div>
            <div className="c-chart-label">{props.label}</div>
            <div className="o-horizontal-legend">
                {props.items.map((item, key) => (
                    <div key={key}>

                        <HorizontalLegendItem
                            item={item}
                            color={getColorFromColorArray(colorCount++, props.colors)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}


export default HorizontalLegend;