const colors = {
    blues: ["#289BF6", "#3CB4FF"],
    chart: ["#F3575E", "#12AC74", "#FFCB01"],
    greens: ["#12AC74",
        "#88BB69",
        "#137D56",
        "#80912A",
        "#8FA178",
        "#0D3621",],
    yellows: [ "#FFCB01", "#EBB701"],
    dark_blues: ["#35ACF8", "#16356F"],
    red: '#F3575E',
}

export default colors;