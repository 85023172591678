import React, {Component} from "react";
import {
    tada,
    shake,
    fadeInDown,
    bounceOutLeft,
    wobble
} from "react-animations";
import Radium, {StyleRoot} from "radium";
import Confetti from "react-dom-confetti";
import TaDaSound from "../../../sounds/tada1.mp3";
import ReactTooltip from 'react-tooltip'

const styles = {
    tada: {
        animation: "x 3s",
        animationName: Radium.keyframes(tada, "tada")
    },
    shake: {
        animation: "x 1s",
        animationName: Radium.keyframes(shake, "shake")
    },
    fadeInDown: {
        animation: "x 1s",
        animationName: Radium.keyframes(fadeInDown, "fadeInDown")
    },
    wobble: {
        animation: "x 1s",
        animationName: Radium.keyframes(wobble, "wobble")
    },
    bounceOutLeft: {
        animation: "x 1s",
        animationName: Radium.keyframes(bounceOutLeft, "bounceOutLeft")
    }
};
const confettiSettings = {
    stagger: 30,
    duration: 8000,

    dragFriction: 0.1,
    elementCount: 100,
    startVelocity: 60,
    angle: 90,
    spread: 120,
    height: "10px",
    width: "10px",
    colors: ["#FFCB01", "#F3575E", "#12AC74", "#BADAEF", "#16356F"]
};

export default class OrderTableItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            played: false,
            confetti: false,
            resetStyling: false,
            highlight: "",
            highlightStyle: null
        };
    }

    componentDidMount = () => {
        this.highlight();
    };
    formatNumber = number => {
        let round = Math.round(number * 100) / 100;
        let pointToComma = round.toString().replace(".", ",");
        let newNumber = pointToComma;
        if (round < 999) {

            let split = newNumber.split(",");

            if (split.length === 1) {
                newNumber += ",00";
            } else if (split[1].length === 1) {
                newNumber += "0";
            }
        }


        return newNumber;
    };

    highlight = () => {
        if (this.state.highlight === "") {
            const totalPrice = this.props.item.total;
            let {played} = this.state;
            if (totalPrice >= 2500) {
                let audio = new Audio(TaDaSound);
                if (totalPrice) {
                    if (played === false) {
                        audio.play();
                    }
                    this.setState({
                        played: true,
                        highlight: "high-order",
                        confetti: true,
                        highlightStyle: styles.tada
                    });
                    setTimeout(
                        function () {
                            this.setState({
                                confetti: false,
                                highlightStyle: styles.bounceOutLeft
                            });
                        }.bind(this),
                        3500
                    );
                    setTimeout(
                        function () {
                            this.setState({
                                highlight: "high-order-in-table",
                                confetti: false,
                                highlightStyle: styles.fadeInDown
                            });
                        }.bind(this),
                        4000
                    );
                }
            } else {
                if (this.state.highlightStyle !== styles.fadeInDown) {
                    this.setState({
                        highlightStyle: styles.fadeInDown
                    });
                }
            }
        }
    };

    render() {
        const {items} = this.props.item;
        const name = this.props.item.customer_name;
        const totalPrice = this.props.item.total;

        return (
            <StyleRoot>
                <div className="confetti">
                    <Confetti
                        config={confettiSettings}
                        active={this.state.confetti}
                    />
                </div>
                <div
                    id="item"
                    style={this.state.highlightStyle}
                    className={"o-table-item " + this.state.highlight}
                >
                    <div className="o-table-item__name">
                        <div className="c-table-item">{name}</div>
                    </div>
                    <div className="o-table-item__ordered-products-container">
                        {items.map((item, key) => (

                            <div key={key} className="o-table-item__products">
                                <div className="o-table-item__product">
                                    <div className="c-table-item">

                                        <p data-tip={item.product}> {item.product}</p>
                                    </div>
                                </div>
                                <div className="o-table-item__product-price">
                                    <div className="c-table-item">
                                        {this.formatNumber(item.total)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="o-table-item__total-price">
                        <div className="c-table-item">
                            {this.formatNumber(totalPrice)}
                        </div>
                    </div>
                    <ReactTooltip/>
                </div>
            </StyleRoot>
        );
    }
}
