import Cookies from 'js-cookie'

function setInitialCookie() {
    let tvMode = Cookies.get('tvMode');
    if (typeof tvMode === 'undefined') {
        Cookies.set('tvMode', true, {expires: 365, sameSite: 'strict'});
    } else {
        let value = Cookies.get('tvMode');
        Cookies.set('tvMode', value, {expires: 365, sameSite: 'strict'});
    }
};

export function getCookie(name) {
    return Cookies.get(name);
};

export function setCookie(name, value) {
    Cookies.set(name, value, {expires: 365, sameSite: 'strict'});
};
export default setInitialCookie;

