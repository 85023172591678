import React from "react";
import NumberBox from "./NumberBox";

/*
NumberBoxes always go in 2. So there are 2 value's and labels expected.

Expected props:
value1={n}
label1={string}
value2={n}
label2={string}

Optional props:
isMoney1={bool} // Displays with euro sign instead of just the number
isMoney2={bool} // Displays with euro sign instead of just the number
prefab1={'string'} // Displays prefab behind the number
prefab2={'string'} // Displays prefab behind the number
growth1={string} // Displays growth, has to be calculated what the growth is in parent.  Recommended: use the 'getGrowth' from functions js
growth2={string} // Displays growth, has to be calculated what the growth is in parent.  Recommended: use the 'getGrowth' from functions js
 */

function NumberBoxes(props) {
    return (
        <div className="s-number-boxes">
            <NumberBox
                tag={props.tag1}
                isMoney={typeof props.isMoney1 !== 'undefined' ? props.isMoney1 : false}
                value={!isNaN(props.value1) ? props.value1 : 0}
                label={props.label1}
                growth={props.growth1}
                prefab={props.prefab1}
            />
            <NumberBox
                isMoney={typeof props.isMoney2 !== 'undefined' ? props.isMoney2 : false}
                value={!isNaN(props.value2) ? props.value2 : 0}
                label={props.label2}
                growth={props.growth2}
                prefab={props.prefab2}
                decimal={props.decimal2}
            />
        </div>
    );
}

export default NumberBoxes;
