import React, {useState} from 'react';
import Header from "../Headers/Header";
import NumberBoxes from "../NumberBoxes/NumberBoxes";
import {connect} from "react-redux";
import colors from '../Variables/Colors'
import PerformanceBar from "../Charts/PerformanceBar";
import {getMonthDutch, confetti, getGrowth, getYear} from '../Functions';

function Main(props) {
    const [played, setPlayed] = useState(false);
    let performanceLabel = "Maandoverzicht " + getMonthDutch();


    return (
        <div id="omzet" className="block">
            <Header
                label={"Momentopname"}
                color={"yellow"}
                number={Math.round(props.revenueToday)}
                isMoney={true}
            />
            <NumberBoxes
                value1={Math.round(props.revenueTodayTillNowLastYear)}
                label1={"Momentopname " + (getYear()-1)}
                growth1={getGrowth(props.revenueToday, props.revenueTodayTillNowLastYear)}
                isMoney1={true}
                value2={Math.round(props.revenueTodayLastYear)}
                label2={"Dagopname " + (getYear()-1)}
                growth2={getGrowth(props.revenueToday, props.revenueTodayLastYear)}
                isMoney2={true}
            />
            <PerformanceBar label={performanceLabel} isPercentage={true} valueNow={props.revenueThisMonth} valuePrevious={props.revenueThisMonthTillNowLastYear}
                            colors={colors.yellows}/>
            {confetti(played, setPlayed, props.revenueToday, props.revenueTodayLastYear)}
        </div>
    );
}

const mapStateToProps = (state = {}) => {
    const result = {
        revenueToday: 0,
        revenueTodayLastYear: 0,
        revenueTodayTillNowLastYear: 0,
        revenueThisMonth: 0,
        revenueThisMonthTillNowLastYear: 0
    };
    if (
        typeof state.data !== "undefined" &&
        typeof state.data["event-current-stats"] !== "undefined"
    ) {
        let result = {};

        state.data["event-current-stats"].forEach(function (
            current_stat_response
        ) {
            result[current_stat_response.label] = current_stat_response.result;
        });
        return {
            revenueToday: result.revenueToday,
            revenueTodayLastYear: result.revenueTodayLastYear,
            revenueTodayTillNowLastYear: result.revenueTodayTillNowLastYear,
            revenueThisMonth: result.revenueThisMonth,
            revenueThisMonthTillNowLastYear: result.revenueThisMonthTillNowLastYear
        };
    }
    return result;
};
export default connect(mapStateToProps)(Main);