import React from 'react';
import NumberBoxes from "../common/NumberBoxes/NumberBoxes";
import {getGrowth} from "../common/Functions";

function UsersBody(props) {
    let {dataPast30Days, dataLastYear} = props;
    const newUsers = Math.round(dataPast30Days.newUsers);
    const newUsersLastYear = Math.round(dataLastYear.newUsers);
    const returningUsers = Math.round(dataPast30Days.users - dataPast30Days.newUsers);
    const returningUsersLastYear = Math.round(dataLastYear.users - dataLastYear.newUsers);
    // const sessionsPerUser = Math.round(dataPast30Days.sessions / dataPast30Days.users * 100) / 100;
    // const sessionsPerUserLastYear = Math.round(dataLastYear.sessions / dataLastYear.users * 100) / 100;
    const averageOrderValue = Math.round(dataPast30Days.averageOrderValue * 100) / 100;
    const averageOrderValueLastYear = Math.round(dataLastYear.averageOrderValue * 100) / 100;
    const conversionRatio = Math.round(dataPast30Days.transactionsPerSession * 100) / 100;
    const conversionRatioLastYear = Math.round(dataLastYear.transactionsPerSession * 100) / 100;
    const revenuePerUser = Math.round(dataPast30Days.revenuePerUser * 100) / 100;
    const revenuePerUserLastYear = Math.round(dataLastYear.revenuePerUser * 100) / 100;
    const transactionRevenue = Math.round(dataPast30Days.transactionRevenue * 100) / 100;
    const transactionRevenueLastYear = Math.round(dataLastYear.transactionRevenue * 100) / 100;


    return (

        <div>
            <NumberBoxes
                label1={'Opbrengst ' }
                growth1={getGrowth(transactionRevenue, transactionRevenueLastYear)}
                value1={parseInt(transactionRevenueLastYear)}
                isMoney1={true}

                value2={conversionRatio} growth2={getGrowth(conversionRatio, conversionRatioLastYear)}
                label2='Conversie ratio' prefab2={'%'}

            />
            <NumberBoxes

                value1={averageOrderValue}
                growth1={getGrowth(averageOrderValue, averageOrderValueLastYear)}
                label1='Gemiddelde orderwaarde' isMoney1={true}

                label2='Omzet per gebruiker'
                isMoney2={true} growth2={getGrowth(revenuePerUser, revenuePerUserLastYear)}
                value2={revenuePerUser}

            />


            <NumberBoxes value1={newUsers}
                         growth1={getGrowth(newUsers, newUsersLastYear)}
                         label1='Nieuwe gebruikers'
                         label2='Terugkerende gebruikers'
                         growth2={getGrowth(returningUsers, returningUsersLastYear)}
                         value2={returningUsers}
            />
        </div>
    )
        ;
}


export default UsersBody;
