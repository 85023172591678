import React from 'react';
import Switch from "react-switch";
// eslint-disable-next-line
import {Route, Link, Redirect} from "react-router-dom"
import {connect} from "react-redux";
import {TvModeOn, TvModeOff} from "../../../../actions/actions";
import {bindActionCreators} from 'redux';


function MenuSettings(props) {
    function handleChange(checked) {
        if (checked) {
            props.TvModeOn()
        } else {
            props.TvModeOff()
        }
    }
    return (
        <div className="o-tv-switch">
            <div className="o-tv-switch__label">TV mode</div>
            <div className="o-tv-switch__switch"><Switch onChange={handleChange} checked={props.tvMode}/>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    tvMode: state.app.tvMode
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            TvModeOn,
            TvModeOff
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(MenuSettings);

