import React from 'react';

/*
Expected props:
name={'string'}
price={n}
imgLink={'string'}
 */
function Product(props) {
    return (
        <div className='o-product__container'>
            <div className='c-label text-center'>Laatst verkochte nieuw product</div>
            <div className='o-product'>
                <div className='o-product__img'>
                    <img
                        src={props.img}
                        alt="product"
                    />
                </div>
                <div className='o-product__productname'>
                    {props.name}
                </div>
                <div className='o-product__price'>
                    {props.price},-
                </div>
            </div>
        </div>
    );
}

export default Product;