import React, {useState} from "react";
import DigitRoll from "digit-roll-react";
import Tag from "../Tag";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";


export function NumberBox(props) {
    const [isDecimalNumber, setIsDecimalNumber] = useState(false);

    let growth = <div/>;
    if (props.growth) {
        if (props.growth === 0) {
            growth = <div>0%</div>
        } else if (props.growth < 0) {
            growth = <div className="negative"><FontAwesomeIcon
                icon={faArrowDown}/> {Math.abs(props.growth)}%</div>
        } else if (props.growth > 0) {
            growth = <div className="postive"><FontAwesomeIcon
                icon={faArrowUp}/> {props.growth}%</div>
        }

    }
    let tag = null;
    if (typeof props.tag !== "undefined") {
        tag = <Tag amount={props.amount} for={props.tag}/>
    }
    let value = props.value;
    let behindDecimal;
    let beforeDecimal;

    if (!Number.isInteger(props.value)) {
        const val = Number(value).toFixed(2);
        const str = val.toString();
        beforeDecimal = str.substring(0, str.length - 3);
        behindDecimal = str.substring(str.length - 2, str.length);
        Number(beforeDecimal).toFixed(2);
        Number(behindDecimal).toFixed(2);

        if (!isDecimalNumber) {
            setIsDecimalNumber(true);
        }
    }

    return (
        <div className="o-number-box">
            {tag}
            <div className="c-label">{props.label}</div>
            <div className="o-number-box__number">
                <div className="c-roller_number">
                    {isDecimalNumber ?
                        <div className='o-number-box__divider'>
                            {props.isMoney ? "€" : null}
                            <DigitRoll
                                num={beforeDecimal}
                                delay={1.4}
                                length={beforeDecimal.length}
                            />
                            ,
                            <DigitRoll
                                num={behindDecimal}
                                delay={1.4}
                                length={behindDecimal.length}
                            />
                        </div>
                        :
                        <div className='o-number-box__divider'>
                            {props.isMoney ? "€" : null}
                            <DigitRoll
                                num={value}
                                delay={1.4}
                                length={value.length}
                                divider="."
                            />
                            {props.suffix ? props.suffix : null}
                        </div>
                    }
                    {props.prefab}
                </div>
            </div>
            <div className="o-number-box__growth">{growth}</div>
        </div>
    );
}

export default NumberBox;
