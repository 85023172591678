import { RECEIVE_DATA } from '../actions/types'

const initialState = {
}

export default function (state = initialState, action) {
  switch(action.type) {
    case RECEIVE_DATA:
      var newState = {};
      newState[action.payload.event] = action.payload.data;
      return {
        ...state,
        ...newState
      };
    default:
      return state
  }
}
