import React from 'react';
import {ResponsiveBar} from '@nivo/bar'
import {getKeys, round, getMonthDutch} from "../Functions";
import ColorVars from "../Variables/Colors"
/*
Expected props:
valueNow={n}
valuePrevious={n}

Optional props:
colors={colors.yellows} (find colors here: '/common/Variables/Colors')
isPercentage={true} (adds % behind numbers)
label='string' (Label above chart)
 */

function PerformanceBar(props) {
    let color;
    let max;
    let format;
    let {valueNow, valuePrevious, label, isPercentage} = props;
    let colors = [...props.colors];
    let performance = round((valueNow - valuePrevious) / valuePrevious * 100);
    performance.toString().charAt(0) !== '-' ? color = colors[0] : color = ColorVars.red;
    if (performance > 0) {
        max = Math.ceil(performance / 10) * 10;
    } else {
        max = Math.ceil((performance * -1) / 10) * 10 * -1;
    }
    if (isPercentage) {
        format = v => `${v}%`;
    }
    let month = getMonthDutch();
    let year = new Date().getFullYear() - 1;
    let data = [
        {
            "line": "Groei t.o.v. " + month + ' ' + year,
            "Procent": performance,
        }
    ];
    let keys = getKeys(data);
    return (
        <div className='o-performance-bar__container'>
            <div className="c-chart-label">{label}</div>
            <div className='o-performance-bar__bar'>
                <ResponsiveBar
                    data={data}
                    keys={keys.keys}
                    indexBy={keys.indexBy}
                    margin={{top: 0, right: 0, bottom: 0, left: 0}}
                    padding={0}
                    innerPadding={9}
                    minValue={max < 0 ? max : -max}
                    maxValue={max > 0 ? max : -max}
                    layout="horizontal"
                    colors={color}
                    axisLeft={null}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    labelFormat={format}
                    tooltipFormat={format}
                />
            </div>

            <div className='o-performance-bar__numbers'>
                <div>{-max}{isPercentage ? '%' : null}</div>
                <div>0</div>
                <div>{max}{isPercentage ? '%' : null}</div>
            </div>
        </div>
    );
}

export default PerformanceBar;
