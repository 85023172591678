let messages = {};
messages.bad = [
        'Dat kan beter'
        // 'Kom op joh!',
        // 'FF vlammen!',
        // 'Gas erop!',
        // 'Vámos',
        // 'Nog ff doorzetten!',
        // 'Zo duren de dagen lang',
        // 'Durwerken',
        // 'Da kan een stuk beter',
        // 'Dit lijkt echt helemaal nergenst op',
        // 'Man man man...',
        // 'Doe is door! ',
        // 'Vaak ben je te bang...',
        // 'Dat is echt erg',
        // 'Oohhh wat ergggg',
        // 'Tis geen Kersemus!',
        // 'zas?',
        // '5 orders..... op je muil... gauw!',
        // 'Het is zeker winter',
        // 'Goade nog wè doen of wa?',
        // 'Zkr brommers kieken',
        // 'Send more stagiaires',
        // 'Zeker zalm aan het roken buiten?',
        // 'Erik is zeker zelf aan de gang?',
        // 'Wan container-tempo joh',
        // 'Vergeet die snacks maar vrijdag',
        // 'Zijn de peuke weer in de aanbieding?',
        // 'Die rooie uit gestel is nog sneller',
        // 'Man bijt hond heeft nog snellere mensen',
        // 'Zo mogen jullie niet meer op kantoor komen.',
        // 'Git gud',
        // 'Pingpingping de sjaak',
        // "Is't pauze?",
        // 'Stop met pingpong, doorwerken',
        // 'WAKE UP'
];
messages.okay = [
    'Het begint ergenst op te lijken'
    // "t Begint ergenst op te lijken",
    // "Dat kan beter",
    // "Dúrwerken",
    // "Doorbeunen nondedju",
    // "2017 belde, ze willen hun aantallen terug",
    // "No pain no game",
    // "Mwah wel een bietje schraal",
    // "Gaat beter",
    // "Das alvast iets",
    // "Aanpakken",
    // "Bietje op en af",
    // "Je komt ook niet half zat in de kroeg, ga voluit"
];
messages.good = [
    'Goed bezig'
    // "Hopppaa",
    // "Lekker bezig mannen",
    // "Heerlijk.",
    // "Sów Hé!",
    // "Hatseflats",
    // "Bijna weekend",
    // "Juist",
    // "JAAAZEEETIE",
    // "Da is ok",
    // "Gu mooi",
    // "Stelletje vakbuffels",
    // "You got this",
    // "Dinie helpt zeker mee"
];
messages.incredible = [
    "TURRRRBBOOOOO",
    "Strijders!",
    "Huts",
    "Erik is zeker zelf aan de gang?",
    "Helemaal dol!",
    "KAY gek!",
    "Jullie janken wel lekker door zeg",
    "Kiek ze goan",
    "Kra-ka-kaaaaaaaaa",
    "Goed bezig! Gullie magazijn",
    "Meer kracht dan een John Deere 6320",
];
export default messages;
