import React from 'react';

function MobileMenu() {

    // return <div className='s-mobile-menu'>
    //     <div className='s-mobile-menu__item'>💰</div>
    //     <div className='s-mobile-menu__item'>🎟️</div>
    //     <div className='s-mobile-menu__item'>📦</div>
    // </div>;
    return <div/>;
}

export default MobileMenu;