import React, { Component } from "react";

export default class RMALine extends Component {
    render() {
        let { number } = this.props;
        let { label } = this.props;
        return (
            <div className="rma-line">
                <span className="blue typpo">{number}</span>
                {label}
            </div>
        );
    }
}
