import React, {useState} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Header from "../common/Headers/Header";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faEnvelope,
    faGlobe,
    faPaperPlane,
    faSearchDollar,
    faSeedling,
    faHistory
} from '@fortawesome/free-solid-svg-icons'
import {connect} from "react-redux";
import {useInterval} from "../common/Functions";
import UsersBody from "./UsersBody"
import settingsVars from "../common/Variables/SettingsVariables";

function Marketing(props) {
    const slideNames = ['Cost Per Click', 'Organish', 'Direct', 'E-mail', 'Totaal'];
    let [tabIndex, setTabIndex] = useState(0);
    let [headerNumbers, setHeaderNumbers] = useState([0, 0, 0, 0, 0]);
    useInterval(() => {
        if (props.result.tvMode) {
            if (tabIndex < 4) {
                setTabIndex(tabIndex + 1)
            } else {
                setTabIndex(0)
            }
        }
    }, settingsVars.timeBetweenSlider);
    if (typeof props.result.analytics !== "undefined") {
        if (typeof props.result.analytics.last30days !== "undefined") {
            if (props.result.analytics.last30days.cpc.transactionRevenue !== headerNumbers[0]) {
                let arr = [];
                arr.push(props.result.analytics.last30days.cpc.transactionRevenue);
                arr.push(props.result.analytics.last30days.organic.transactionRevenue);
                arr.push(props.result.analytics.last30days.direct.transactionRevenue);
                arr.push(props.result.analytics.last30days.email.transactionRevenue);
                arr.push(props.result.analytics.last30days.all.transactionRevenue);
                setHeaderNumbers(arr);
            }
        }
    }

    return (
        <div className='block'>
            <Header isMoney={true} number={headerNumbers[tabIndex]} color={'green-flat'} label={slideNames[tabIndex] + ' opbrengt afgelopen 30 dagen'}/>
            <Tabs selectedIndex={tabIndex} onSelect={tabIndex => setTabIndex(tabIndex)}>
                <TabList className='react-tabs__tab-list green-tabs'>


                    <Tab><FontAwesomeIcon className='react-tabs__icon'
                                          icon={faSearchDollar}/></Tab>
                    <Tab><FontAwesomeIcon className='react-tabs__icon'
                                          icon={faSeedling}/></Tab>
                    <Tab><FontAwesomeIcon className='react-tabs__icon'
                                          icon={faPaperPlane}/></Tab>
                    <Tab><FontAwesomeIcon className='react-tabs__icon'
                                          icon={faEnvelope}/></Tab>
                    <Tab><FontAwesomeIcon className='react-tabs__icon'
                                          icon={faGlobe}/></Tab>
                </TabList>
                <div className='c-titleLabel'><FontAwesomeIcon
                    icon={faHistory}/> 30 dagen t.o.v. vorig jaar
                </div>
                <TabPanel>
                    {/*CPC*/}
                    <UsersBody section={'cpc'} dataPast30Days={props.result.analytics.last30days.cpc}
                               dataLastYear={props.result.analytics.lastYear30days.cpc}/>
                </TabPanel>

                <TabPanel>
                    {/*Organic*/}
                    <UsersBody section={'organic'} dataPast30Days={props.result.analytics.last30days.organic}
                               dataLastYear={props.result.analytics.lastYear30days.organic}/>
                </TabPanel>

                <TabPanel>
                    {/*Direct*/}
                    <UsersBody section={'direct'} dataPast30Days={props.result.analytics.last30days.direct}
                               dataLastYear={props.result.analytics.lastYear30days.direct}/>
                </TabPanel>
                <TabPanel>
                    {/*Mail*/}
                    <UsersBody section={'mail'} dataPast30Days={props.result.analytics.last30days.email}
                               dataLastYear={props.result.analytics.lastYear30days.email}/>
                </TabPanel>
                <TabPanel>
                    {/*Total*/}
                    <UsersBody section={'total'} dataPast30Days={props.result.analytics.last30days.all}
                               dataLastYear={props.result.analytics.lastYear30days.all}/>
                </TabPanel>
            </Tabs>
        </div>
    );
}


const mapStateToProps = state => {
    let result = {};


    if (typeof state.data['analytics-users'] !== "undefined") {
        result.analytics = state.data['analytics-users'];
    }


    result.tvMode = state.app.tvMode;
    return {result};
};

export default connect(
    mapStateToProps,
    {}
)(Marketing);
