import React, {Component} from "react";
import OrderTableItem from "./OrderTableItem";

export default class OrdersTable extends Component {
    render() {
        let {data} = this.props;
        return (
            <div className="s-table-container">
                <div className="s-table-container__box">
                    <div className="o-table-item__header">
                        <div className="o-table-item">
                            <div className="o-table-item__incomingdate">
                                <div className="c-table-item">Datum</div>
                            </div>
                            <div className="o-table-item__count">
                                <div className="c-table-item">Regels</div>
                            </div>
                            <div className="o-table-item__count">
                                <div className="c-table-item">Orders</div>
                            </div>
                        </div>
                    </div>

                    {data
                        .map((item, key) => {
                            return (<OrderTableItem item={item} key={item.date}/>);
                        })}
                </div>
            </div>
        );
    }
}
