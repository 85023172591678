import React from 'react';
import {redirectToTarget} from "./Functions";

function BackArrow(props) {
    return (
        <div className='o-back-arrow' onClick={() => redirectToTarget(props.link)}>
            <div>🢀 Terug naar {props.name}</div>
        </div>
    );
}

export default BackArrow;