import React from "react";
import { connect } from "react-redux";

function Main(props) {
  return (
    <div className="block">
      <div id="visitors" className="s-visitors-container ">
        <span className="blue typpo">{props.visitors.totalVisitors}</span>{" "}
        bekijken op dit moment Toppy.nl
      </div>
    </div>
  );
}
const mapStateToProps = (state = {}) => {
  if (
    typeof state.data !== "undefined" &&
    typeof state.data["realtime-analytics"] !== "undefined"
  ) {
    return {
      visitors: state.data["realtime-analytics"],
    };
  }

  return {
    visitors: [],
  };
};

export default connect(mapStateToProps)(Main);
