import React, {Component} from "react";

import Omzet from "./common/Omzet/Main";
import Orders from "./common/Orders/Main";
//import Reviews from "./Reviews/Main";

import {
    NAMESPACE_DEFAULT,
    NAMESPACE_CONTENT,
} from "../actions/types";

import Marketing from "./Content/Marketing"
import {connect} from "react-redux";
import {openWebsocket} from "../actions/actions";
import PropTypes from "prop-types";
import MobileMenu from "./common/Profile/Mobile/MobileMenu";
import Loader from "./common/Loader";
import Reviews from "./common/Reviews/Main";
import Departments from "./Content/Departments"
import Achievements from "./Content/Achievements";
import Browsing from "./common/Browsing/Main"

class Content extends Component {
    componentDidMount() {
        this.connectSockets();
    }

    connectSockets = () => {
        this.props.openWebsocket(NAMESPACE_DEFAULT);
        this.props.openWebsocket(NAMESPACE_CONTENT);
    }


    render() {
        let {loaded} = this.props.result;
        if (loaded.revenue && loaded.analytics && loaded.revenuePerDepartment) {
            if (this.props.history.location.pathname === '/content') {
                return (
                    <div>
                        <div className={this.props.result.tvMode ? "content-dashboard tv" : "content-dashboard"}>
                            <div id="left" className="medium-column">
                                <Omzet/>
                                <Orders/>
                            </div>
                            <div id="center" className="medium-column">
                                <Marketing/>
                                <Reviews/>
                            </div>
                            <div id="right" className="medium-column">
                                <Departments />
                                <Achievements />
                                <Browsing />
                            </div>
                        </div>
                        <MobileMenu/>
                    </div>
                );
            } else {
                return null
            }
        } else {
            return <Loader show={true}/>
        }


    }
}

Content.propTypes = {
    openWebsocket: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    let result = {};
    let loaded = {revenue: false};

    if (typeof state.data["event-current-stats"] !== "undefined") {
        loaded.revenue = true;
    }
    if (typeof state.data['analytics-users'] !== "undefined") {
        loaded.analytics = true;
    }
    if (typeof state.data['event-revenue-per-department'] !== "undefined") {
        loaded.revenuePerDepartment = true;
    }

    console.log('loaded', loaded);

    result.loaded = loaded;
    result.tvMode = state.app.tvMode;
    return {result};
};

export default connect(
    mapStateToProps,
    {openWebsocket}
)(Content);
