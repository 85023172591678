import moment from 'moment';

export function getCutoffTime(cutoffSetting) {
    const cutoff = moment(cutoffSetting);
    const now = moment();

    let difference = moment.utc(moment(cutoff, "DD/MM/YYYY HH:mm:ss").diff(moment(now, "DD/MM/YYYY HH:mm:ss")));

    const hours = difference.get('hours');
    const minutes = difference.get('minutes');

    return {
        hours,
        minutes
    }
}
