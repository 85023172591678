import React, {Component} from "react";
import Header from "../../common/Headers/Header";
import {connect} from "react-redux";
import RMALine from "./RMALine";
import Bar from "../../common/Charts/Bar";
import colors from "../../common/Variables/Colors";
import {getKeys} from "../../common/Functions";

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    Werkzaamheden: "",
                    klantenservice: 0
                }
            ],
            DataLabels: ["nieuw", "klantenservice", "max"],
            totalCustomerService: 0,
            newRMA: 0,
            customerService: 0,
            repair: 0,
            solved: 0,
            customer: 0,
            nieuw: 0,
            warehouse: 0
        };
    }

    setMax = () => {
        //This is for the customer service to see if they have a lot of work to do. This has to scale per month, in the summer it is more likely to have more RMA.
        const d = new Date();
        const n = d.getMonth() + 1;
        if (n >= 0 && n <= 2) {
            //jan-feb
            return 20;
        } else if (n >= 3 && n <= 5) {
            //mar-mei
            return 100;
        } else if (n >= 6 && n <= 8) {
            //"jun-augustus")
            return 50;
        } else if (n >= 9 && n <= 12) {
            //"sep-december"
            return 20;
        } else {
            //error
            return 2000;
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            let newRMA = 0;
            let customerService = 0;
            let repair = 0;
            let solved = 0;
            let customer = 0;
            let totalToDo = 0;
            let warehouse = 0;
            const newMax = this.setMax();

            if (this.props.RMA !== null && this.props.RMA !== []) {
                if (this.props.RMA.data !== undefined) {
                    this.props.RMA.data.forEach(element => {
                        if (element.label === "New") {
                            newRMA += element.count;
                        } else if (element.label === "Customer Service") {
                            totalToDo += element.count;
                            customerService += element.count;
                        } else if (element.label === "Customer") {
                            customer += element.count;
                        } else if (element.label === "Repair") {
                            repair += element.count;
                        } else if (element.label === "Solved") {
                            solved += element.count;
                        } else if (element.label === "Warehouse") {
                            warehouse += element.count;
                        }
                    });
                }
            }

            this.setState({
                totalCustomerService: totalToDo,
                newRMA,
                customerService,
                customer,
                repair,
                solved,
                warehouse,
                max: newMax,
                nieuw: newRMA,
                data: [
                    {
                        Werkzaamheden: "",
                        klantenservice: customerService
                    }
                ]
            });
        }
    }

    render() {
        let rmaKeys = getKeys(this.state.data);
        return (
            <div id="rma" className="block">
                <Header
                    label={"Klantenservice RMA"}
                    color={"blue"}
                    number={this.state.totalCustomerService}
                />
                <div className="rma-content">
                    <div>
                        <Bar
                            label='RMA'
                            items={rmaKeys.keys}
                            dataLabels={this.state.DataLabels}
                            indexBy={rmaKeys.indexBy}
                            color={colors.blues}
                            data={this.state.data}
                            max={this.state.max}
                        />
                    </div>
                    <div className="rma-lesser">
                        <RMALine
                            number={this.state.warehouse}
                            label={"Magazijn"}
                        />
                        <RMALine
                            number={this.state.nieuw}
                            label={"Nieuw"}
                        />
                        <RMALine number={this.state.customer} label={"Klant"}/>
                        <RMALine
                            number={this.state.repair}
                            label={"Reparatie"}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state = {}) => {
    if (
        typeof state.data !== "undefined" &&
        typeof state.data["event-rma-per-state-count"] !== "undefined"
    ) {
        var result = {
            labels: [],
            data: []
        };

        state.data["event-rma-per-state-count"].forEach(function (rma_response) {
            result.labels.push(rma_response.label);
            result.data.push(rma_response);
        });

        return {
            RMA: result
        };
    }

    return {
        RMA: []
    };
};

export default connect(mapStateToProps)(Main);
