import PartySound from "../../sounds/party1.mp3";
import Confetti from "react-confetti";
import {confettiSettings} from "./Variables/SettingsVariables";
import React, {useEffect, useRef} from "react";


export function confetti(played, setPlayed, value, targetValue) {
    const { innerWidth: width} = window;

    if (value > targetValue) {
        let audio = new Audio(PartySound);
        if (played === false) {
            audio.play();
            setPlayed(true);
        }

        return (
            <div>
                {width < 3000 ? <><Confetti recycle={false} pieces={10}/>
                    <div className="background">
                        <Confetti pieces={confettiSettings.pieces} recycle={confettiSettings.recycle}
                                  wind={confettiSettings.wind} gravity={confettiSettings.gravity}
                                  opacity={confettiSettings.opacity}/>
                    </div> </>: null}

            </div>
        );
    } else {
        return <div className="background"></div>;
    }
}

export function setMaxHorizontal(data) {
    /*

Sets max of Horizontal bar graphs
Expected: data = [{"line": "Pallets","picking": 6,"Processing": 2},{"line": "Ophalen","picking": 4,"Processing": 0},{"line": "PostNL","picking": 24,"Processing": 3}]
* */
    let max = 0;
    let total = 0;
    setMaxLineGraph();
    data.forEach(element => {
        const values = Object.values(element);
        for (const value of values) {
            if (Number.isInteger(value)) {
                total += value;

                if (total > max) {
                    max += total;
                }
                max = Math.ceil(total / 10) * 10;
            }
        }
    });

    return max;
}
export function getWindowSize(){}

export function getGrowth(present, past) {
    return(Math.round(((present - past) / past * 100) * 10) / 10);
}

//Create easy intervals
export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
export function setMaxLineGraph(data) {
    let max = 0;
    if (data !== undefined) {
        max = Math.max(
            ...(data.map(dataElement => {
                return Math.max(
                    ...(dataElement.data.map(axis => {
                        return parseInt(axis.y)
                    }))
                );
            }))
        );
    }

    return Math.ceil((max / 4 )/ 100)*100 *4;
}

//Check if object is emtpy
export function isEmptyObject(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function getLastHours(amount) {
    let dt = new Date();
    let arr = [], i;
    for (i = 0; i < amount; i++) {
        arr.push(dt.getHours() - i);
    }
    return arr;
}

//Works only for 'event-current-stats'
export function getAmountFromLabelInArray(whatToFind, array) {
    let result = 0;
    if (!isEmptyObject(array)) {
        let obj = array.filter(item => {
            return item.label === whatToFind
        });
        result = obj[0].result;
    }
    return result;
}


export function getKeys(data) {
    /*
     Returns all keys. keys.indexBy is the first entry of the array.

     Example:
     data = [{work: '', customerService: 3, new: 5}
     varName.keys would be [customerService, new]
     varName.indexBy would be 'work'
    */
    let keys = {};
    let allKeys = Object.keys(data[0]);
    keys.keys = allKeys.slice(1);
    keys.indexBy = allKeys[0];
    return keys;
}

export function round(value, precision) {
    let multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export function getMonthDutch() {
    /*
        Returns the current month, in the Dutch language
    */
    const monthNames = ["januari", "februari", "maart", "april", "mei", "juni",
        "july", "augustus", "september", "oktober", "november", "december"
    ];
    const d = new Date();
    return monthNames[d.getMonth()];
}
export function getYear() {
    const d = new Date();
    return parseInt(d.getFullYear());
}
export function totalValueOfItemsInArray(array) {
    /*
        Returns the total value of all the items in an array counted up.

         Example:
         array = [{work: '', customerService: 3, new: 5}
         Expected return: 8
    */
    let total = 0;
    array.map(item => Number.isInteger(item) ? total += item : null);
    return total;
}

export function getColorFromColorArray(count, colorArray) {
    return colorArray[count];
}

export function redirectToTarget(target) {
    return window.location.href = target;
}
