import {
    DECREMENT_PICKER,
    INCREMENT_PICKER,
    SET_KPI_PICKER,
    INCREMENT_PACKER,
    DECREMENT_PACKER,
    SET_KPI_PACKER
} from "../actions/types";

const initialState = {
    pickers: 1,
    packers: 1,
    kpiPickers: 60,
    kpiPackers: 40,
};

const pickerCounter = (state = initialState, action) => {
    switch (action.type) {
        case INCREMENT_PICKER:
            return {
                ...state,
                pickers: state.pickers + 1
            };

        case DECREMENT_PICKER:
            if (state.pickers === 0) {
                return {...state}
            }
            return {
                ...state,
                pickers: state.pickers - 1
            };
        case SET_KPI_PICKER:
            return {
                ...state,
                kpiPickers: action.amount
            };
        case INCREMENT_PACKER:
            return {
                ...state,
                packers: state.packers + 1
            };

        case DECREMENT_PACKER:
            if (state.pickers === 0) {
                return {...state}
            }
            return {
                ...state,
                packers: state.packers - 1
            };
        case SET_KPI_PACKER:
            return {
                ...state,
                kpiPackers: action.amount
            };
        default:
            return {
                ...state
            }
    }
};
export default pickerCounter;