import React, {Component} from "react";

import Omzet from "./common/Omzet/Main";
import Tickets from "./CustomerService/Tickets/Main";
import RMA from "./CustomerService/RMA/Main";
import Orders from "./common/Orders/Main";
import Reviews from "./common/Reviews/Main";
import Browsing from "./common/Browsing/Main";
import Overview from "./CustomerService/Overview/Main";

import {
    NAMESPACE_DEFAULT,
    NAMESPACE_CUSTOMER_SERVICE,
    NAMESPACE_RMA,
} from "../actions/types";

import {connect} from "react-redux";
import {openWebsocket} from "../actions/actions";
import PropTypes from "prop-types";
import MobileMenu from "./common/Profile/Mobile/MobileMenu";
import Loader from "./common/Loader";

class CustomerService extends Component {
    componentDidMount() {
        this.connectSockets();
    }

    connectSockets = () => {
        this.props.openWebsocket(NAMESPACE_DEFAULT);
        this.props.openWebsocket(NAMESPACE_CUSTOMER_SERVICE);
        this.props.openWebsocket(NAMESPACE_RMA);
    }


    render() {
        let {loaded} = this.props.result;
        if (loaded.tickets && loaded.reviews && loaded.revenue && loaded.rma) {
            if (this.props.history.location.pathname === '/customer-service') {
                return (
                    <div>
                        <div className={this.props.result.tvMode ? "customer-service tv" : "customer-service"}>
                            <div id="left" className="medium-column">
                                <Omzet/>
                                <Orders/>
                            </div>
                            <div id="center" className="medium-column">
                                <Tickets/>

                            </div>
                            <div id="right" className="medium-column">
                                <RMA/>
                                <Reviews/>
                                <Overview/>
                                <Browsing/>
                            </div>
                        </div>
                        <MobileMenu/>
                    </div>
                );
            } else {
                return null
            }
        } else {
            return <Loader show={true}/>
        }


    }
}

CustomerService.propTypes = {
    openWebsocket: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    let result = {};
    let loaded = {tickets: false, revenue: false, reviews: false, rma: false};
    loaded.tickets = true;
    if (typeof state.data["event-robin-tickets"] !== "undefined") {
        loaded.tickets = true;
    }
    if (typeof state.data["event-rma-per-state-count"] !== "undefined") {
        loaded.rma = true;
    }
    if (typeof state.data["event-kiyoh-latest-reviews"] !== "undefined") {
        loaded.reviews = true;
    }
    if (typeof state.data["event-current-stats"] !== "undefined") {
        loaded.revenue = true;
    }
    result.loaded = loaded;
    result.tvMode = state.app.tvMode;
    return {result};
};

export default connect(
    mapStateToProps,
    {openWebsocket}
)(CustomerService);
