import React from "react";
import NumberBoxes from "../NumberBoxes/NumberBoxes";
import {connect} from "react-redux";
import OrdersTable from "./OrdersTable";

function Main(props) {
    return (
        <div id="omzet" className="block">
            <NumberBoxes
                tag1={'orderCountLastHour'} // If you rename this, also rename it in Tag.js
                value1={props.orderCountLastHour}
                label1={"Orders laatste uur"}
                value2={props.orderCountToday}
                label2={"Orders vandaag"}
            />
            <OrdersTable data={props.table}/>
        </div>
    );
}

let orderTable = [];
let order;
const mapStateToProps = (state = {}) => {
    const result = {
        data: null,
        table: orderTable
    };

    if (typeof state.data === "undefined") {
        return result;
    }

    if (typeof state.data["event-current-stats"] !== "undefined") {
        state.data["event-current-stats"].forEach(function (
            current_stat_response
        ) {
            result[current_stat_response.label] = current_stat_response.result;
        });
    }
    if (typeof state.data["event-order-place"] !== "undefined"
        && order !== state.data["event-order-place"]) {
        order = state.data["event-order-place"];
        orderTable.unshift(order);
        if (orderTable.length > 5) {
            orderTable.pop();
        }
        result.table = [...orderTable];
    }

    return result;
};

export default connect(mapStateToProps)(Main);
