import React, {Component} from "react";
import OrderTableItem from "./OrderTableItem";

export default class OrdersTable extends Component {
    render() {
        let {data} = this.props;
        return (
            <div className="s-table-container">
                <div className="s-table-container__box">
                    <div className="o-table-item__header">
                        <div className="o-table-item">
                            <div className="o-table-item__name">
                                <div className="c-table-item">Naam</div>
                            </div>
                            <div className="o-table-item__ordered-products-container">
                                <div className="o-table-item__products">
                                    <div className="o-table-item__product">
                                        <div className="c-table-item">
                                            Bestelde artikelen
                                        </div>
                                    </div>

                                    <div className="o-table-item__product-price">
                                        <div className="c-table-item">
                                            Prijs
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="o-table-item__total-price-header">
                                <div className="c-table-item">Totaalprijs</div>
                            </div>
                        </div>
                    </div>

                    {data
                    // .reverse()
                        .map((item, key) => {

                            return (<OrderTableItem item={item} key={item.increment_id}/>);
                        })}
                </div>
            </div>
        );
    }
}
