import React, {Component} from "react";
import Radium, {StyleRoot} from "radium";
import {fadeInDown} from "react-animations";


export default class OrderTableItem extends Component {
    render() {
        const {
            date,
            count_expected,
            order_count_expected
        } = this.props.item;

        return (
            <StyleRoot>
                <div
                    id="item"
                    style={{
                        animation: "x 1s",
                        animationName: Radium.keyframes(fadeInDown, "fadeInDown")
                    }}
                    className={"o-table-item"}
                >
                    <div className="o-table-item__incomingdate">
                        <div className="c-table-item">{date}</div>
                    </div>
                    <div className="o-table-item__count">
                        <div className="c-table-item">
                            {count_expected}
                        </div>
                    </div>
                    <div className="o-table-item__count">
                        <div className="c-table-item">
                            {order_count_expected}
                        </div>
                    </div>
                </div>
            </StyleRoot>
        );
    }
}
