import React, { Component } from "react";
import New from "./Warehouse/common/New";
import Doing from "./Warehouse/common/Doing";
import Done from "./Warehouse/common/Done";
import { openWebsocket, getNamespaceSettings } from "../actions/actions";
import { NAMESPACE_WAREHOUSE } from "../actions/types";
import { connect } from "react-redux";
import Loader from "./common/Loader";
import { getAmountFromLabelInArray } from "./common/Functions";
import { getSettings } from "./Warehouse/ActiveEmployees";
import Countdown from "./Warehouse/common/Countdown";

class Warehouse extends Component {
  componentDidMount() {
    this.connectSockets();
    this.props.getNamespaceSettings(NAMESPACE_WAREHOUSE);
  }

  connectSockets = () => {
    this.props.openWebsocket(NAMESPACE_WAREHOUSE);
  };

  render() {
    let packingPerHour = getAmountFromLabelInArray(
      "currentLabelsPerHour",
      this.props.currentStatus
    );
    if (typeof this.props.data !== "undefined") {
      const {
        count_done,
        count_new,
        count_ready,
        count_processing,
        count_picked,
        shipment_count_done,
        shipment_count_new,
        shipment_count_ready,
        shipment_count_processing,
        shipment_count_picked
      } = this.props.data;

      const totalPakket =
        shipment_count_done + shipment_count_new + shipment_count_ready + shipment_count_processing + shipment_count_picked;
      const totalPallet =
        this.props.dataPallet.shipment_count_done +
        this.props.dataPallet.shipment_count_new +
        this.props.dataPallet.shipment_count_ready +
        this.props.dataPallet.shipment_count_processing +
        this.props.dataPallet.shipment_count_picked;

      const ratio = (totalPakket / (totalPakket + totalPallet)) * 100;

      const currentHour = new Date().getHours();
      const cutoffHour = new Date(this.props.settings.pakketCutOff).getHours();
      const hoursToCutoff = cutoffHour - currentHour;

      return (
        <div className={"customer-service tv"}>
          <div id="left" className="medium-column">
            <New
              rulesComplete={count_done}
              rulesNew={count_new}
              rulesReady={count_ready}
              shipmentsNew={shipment_count_new}
              shipmentsReady={shipment_count_ready}
              newOrdersLastHour={this.props.ordersPerHour}
              hoursToCutoff={hoursToCutoff}
              ratio={ratio}
            />
            <Countdown
              headerText={"Tijd tot cutoff"}
              cutoff={this.props.settings.pakketCutOff}
            />
          </div>
          <div id="center" className="medium-column">
            <Doing
              rulesProcessing={count_processing}
              rulesPicked={count_picked}
              shipmentsProcessing={shipment_count_processing}
              shipmentsPicked={shipment_count_picked}
              pickingPerHour={this.props.pickingPerHour.picking_per_hour}
              pickingGoal={
                this.props.settings.pakketPickGoal *
                this.props.settings.pakketPickers
              }
              activePickers={this.props.active.pakketPickers}
            />
          </div>
          <div id="right" className="medium-column">
            <Done
              rulesDone={count_done}
              shipmentsDone={shipment_count_done}
              packingPerHour={packingPerHour}
              packingGoal={
                this.props.settings.pakketPackGoal *
                this.props.settings.pakketPackers
              }
              activePackers={this.props.settings.pakketPackers}
              pakketCutOff={this.props.settings.pakketCutOff}
              palletCutOff={this.props.settings.palletCutOff}
            />
          </div>
        </div>
      );
    } else {
      return <Loader show={true} />;
    }
  }
}

const mapStateToProps = (state = {}) => {
  const result = { active: {}, currentStatus: {} };

  if (typeof state.data["event-stocky-state-counts"] !== "undefined") {
    result.data = state.data["event-stocky-state-counts"].find(
      (item) => item.flow === "Pakket"
    );
    result.dataPallet = state.data["event-stocky-state-counts"].find(
      (item) => item.flow === "Pallet"
    );
  }
  if (
    typeof state.data["event-stocky-current-picking-per-hour"] !== "undefined"
  ) {
    result.pickingPerHour = state.data[
      "event-stocky-current-picking-per-hour"
    ].find((item) => item.name === "Pakket");
  }
  if (
    typeof state.data["event-stocky-current-packing-per-hour"] !== "undefined"
  ) {
    result.packingPerHour = state.data[
      "event-stocky-current-packing-per-hour"
    ].find((item) => item.name === "Pakket");
  }

  if (typeof state.data["event-current-stats"] !== "undefined") {
    result.currentStatus = state.data["event-current-stats"];

    result.ordersPerHour = getAmountFromLabelInArray(
      "orderCountLastHour",
      state.data["event-current-stats"]
    );
  }

  result.settings = getSettings(state);
  result.tvMode = state.app.tvMode;

  return result;
};

export default connect(mapStateToProps, {
  openWebsocket,
  getNamespaceSettings,
})(Warehouse);
