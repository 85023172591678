import React, {useState} from 'react';
import Header from "../common/Headers/Header";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {getMonthDutch, useInterval} from "../common/Functions";
import settingsVars from "../common/Variables/SettingsVariables";
import {connect} from "react-redux";
import DepartmentBody from "./DepartmentBody"

function Departments(props) {
    let [tabIndex, setTabIndex] = useState(0);
    useInterval(() => {
        if (props.result.tvMode) {
            if (tabIndex < 6) {
                setTabIndex(tabIndex + 1)
            } else {
                setTabIndex(0)
            }
        }
    }, settingsVars.timeBetweenSlider);

    return (
        <div className='block'>
            <Header color={'blue-flat'}
                    number={typeof props.result.headerNumber[tabIndex] !== "undefined" && props.result.headerNumber !== [] ? Math.round(props.result.headerNumber[tabIndex]) : 0}
                    label={"Momentopname " + getMonthDutch()} isMoney={true}/>
            <Tabs selectedIndex={tabIndex} onSelect={tabIndex => setTabIndex(tabIndex)}>
                <TabList className='react-tabs__tab-list blue-tabs'>
                    <Tab><i className='icon icon-zwembad'/></Tab>
                    <Tab><i className='icon icon-vijver'/></Tab>
                    <Tab><i className='icon icon-hottub'/></Tab>
                    <Tab><i className='icon icon-tuin'/></Tab>
                    <Tab><i className='icon icon-kamperen'/></Tab>
                    <Tab><i className='icon icon-outdoor'/></Tab>
                    <Tab><i className='icon icon-toppy-icon'/></Tab>
                </TabList>
                <TabPanel>
                    {/*Zwembad*/}
                    <DepartmentBody dataPast30Days={props.result.analytics.last30days.perDepartment.zwembad}
                                    dataLastYear={props.result.analytics.lastYear30days.perDepartment.zwembad}
                                    dataRevenue={props.result.revenuePerDepartment.zwembad}
                    />
                </TabPanel>

                <TabPanel>
                    {/*Vijver*/}
                    <DepartmentBody dataPast30Days={props.result.analytics.last30days.perDepartment.vijver}
                                    dataLastYear={props.result.analytics.lastYear30days.perDepartment.vijver}
                                    dataRevenue={props.result.revenuePerDepartment.vijver} />
                </TabPanel>

                <TabPanel>
                    {/*Spa*/}
                    <DepartmentBody dataPast30Days={props.result.analytics.last30days.perDepartment.spa}
                                    dataLastYear={props.result.analytics.lastYear30days.perDepartment.spa}
                                    dataRevenue={props.result.revenuePerDepartment.spa} />
                </TabPanel>
                <TabPanel>
                    {/*Tuin*/}
                    <DepartmentBody dataPast30Days={props.result.analytics.last30days.perDepartment.tuin}
                                    dataLastYear={props.result.analytics.lastYear30days.perDepartment.tuin}
                                    dataRevenue={props.result.revenuePerDepartment.tuin} />
                </TabPanel>
                <TabPanel>
                    {/*Kamperen*/}
                    <DepartmentBody dataPast30Days={props.result.analytics.last30days.perDepartment.kamperen}
                                    dataLastYear={props.result.analytics.lastYear30days.perDepartment.kamperen}
                                    dataRevenue={props.result.revenuePerDepartment.kamperen} />
                </TabPanel>
                <TabPanel>
                    {/*Vrije tijd*/}
                    <DepartmentBody dataPast30Days={props.result.analytics.last30days.perDepartment.vrijetijd}
                                    dataLastYear={props.result.analytics.lastYear30days.perDepartment.vrijetijd}
                                    dataRevenue={props.result.revenuePerDepartment.vrijetijd} />
                </TabPanel>
                <TabPanel>
                    {/*Cadeaukaarten*/}
                    <DepartmentBody dataPast30Days={props.result.analytics.last30days.perDepartment.cadeau}
                                    dataLastYear={props.result.analytics.lastYear30days.perDepartment.cadeau}
                                    dataRevenue={props.result.revenuePerDepartment.cadeau} />
                </TabPanel>
            </Tabs>
        </div>
    );
}

const mapStateToProps = state => {
    let result = {latestNewProductSales: []};


    if (typeof state.data['analytics-users'] !== "undefined") {
        result.analytics = state.data['analytics-users'];
    }

    if (typeof state.data['event-revenue-per-department'] !== "undefined") {
        result.revenuePerDepartment = {};
        result.headerNumber = [];

        var revenueMock = {
            "label": "",
            "department_id": 0,
            "revenue_this_month": 0.00,
            "revenue_this_month_last_year": 0.00,
            "revenue_this_month_last_year_till_now": 0
        }

        if (state.data['event-revenue-per-department'] !== []) {
            result.revenuePerDepartment.zwembad = typeof state.data['event-revenue-per-department'].filter(revenue => revenue.department_id === 3)[0] === 'undefined'
                    ? revenueMock
                    : state.data['event-revenue-per-department'].filter(revenue => revenue.department_id === 3)[0]; //Zwembad

            result.revenuePerDepartment.vijver = typeof state.data['event-revenue-per-department'].filter(revenue => revenue.department_id === 1158)[0] === 'undefined'
                ? revenueMock
                : state.data['event-revenue-per-department'].filter(revenue => revenue.department_id === 1158)[0]; //Vijver

            result.revenuePerDepartment.spa = typeof state.data['event-revenue-per-department'].filter(revenue => revenue.department_id === 634)[0] === 'undefined'
                    ? revenueMock
                    : state.data['event-revenue-per-department'].filter(revenue => revenue.department_id === 634)[0]; //Spa

            result.revenuePerDepartment.tuin = typeof state.data['event-revenue-per-department'].filter(revenue => revenue.department_id === 636)[0] === 'undefined'
                    ? revenueMock
                    : state.data['event-revenue-per-department'].filter(revenue => revenue.department_id === 636)[0]; //Tuin

            result.revenuePerDepartment.kamperen = typeof state.data['event-revenue-per-department'].filter(revenue => revenue.department_id === 3475)[0] === 'undefined'
                ? revenueMock
                : state.data['event-revenue-per-department'].filter(revenue => revenue.department_id === 3475)[0]; //Kamperen

            result.revenuePerDepartment.vrijetijd = typeof state.data['event-revenue-per-department'].filter(revenue => revenue.department_id === 1984)[0] === 'undefined'
                ? revenueMock
                : state.data['event-revenue-per-department'].filter(revenue => revenue.department_id === 1984)[0]; //Vrije tijd

            result.revenuePerDepartment.cadeau = typeof state.data['event-revenue-per-department'].filter(revenue => revenue.department_id === 4101)[0] === 'undefined'
                    ? revenueMock
                    : state.data['event-revenue-per-department'].filter(revenue => revenue.department_id === 4101)[0]; //Cadeaukaarten

            result.headerNumber = [result.revenuePerDepartment.zwembad.revenue_this_month, result.revenuePerDepartment.vijver.revenue_this_month,
                result.revenuePerDepartment.spa.revenue_this_month, result.revenuePerDepartment.tuin.revenue_this_month,
                result.revenuePerDepartment.kamperen.revenue_this_month, result.revenuePerDepartment.vrijetijd.revenue_this_month,
                result.revenuePerDepartment.cadeau.revenue_this_month];
        }

        if (
            typeof state.data !== "undefined" &&
            typeof state.data["event-latest-new-product-sales"] !== "undefined"
        ) {

            result.revenuePerDepartment.zwembad.lastSoldItem = state.data["event-latest-new-product-sales"].find(item => item.department === 3)
            result.revenuePerDepartment.vijver.lastSoldItem = state.data["event-latest-new-product-sales"].find(item => item.department === 1158)
            result.revenuePerDepartment.spa.lastSoldItem = state.data["event-latest-new-product-sales"].find(item => item.department === 634)
            result.revenuePerDepartment.tuin.lastSoldItem = state.data["event-latest-new-product-sales"].find(item => item.department === 636)
            result.revenuePerDepartment.kamperen.lastSoldItem = state.data["event-latest-new-product-sales"].find(item => item.department === 3475)
            result.revenuePerDepartment.vrijetijd.lastSoldItem = state.data["event-latest-new-product-sales"].find(item => item.department === 1984)
            result.revenuePerDepartment.cadeau.lastSoldItem = state.data["event-latest-new-product-sales"].find(item => item.department === 4101)
        }

    }

    result.tvMode = state.app.tvMode;
    return {result};
};

export default connect(
    mapStateToProps,
    {}
)(Departments);
