import React from 'react';
import settingsVars from "./Variables/SettingsVariables";
import messages from "./Variables/TagMessages";

function Tag(props) {
    if (props.for === 'orderCountLastHour') {
        const possibleRotations = ['left-rotate', 'right-rotate'];
        if (props.amount > settingsVars.tagOrdersLastHour.incredible) {
            return <div
                className={`c-tag  ${possibleRotations[Math.floor(Math.random() * possibleRotations.length)]}`}>{messages.incredible[Math.floor(Math.random() * messages.incredible.length)]}</div>
        } else if (props.amount > settingsVars.tagOrdersLastHour.good) {
            return <div
                className='c-tag left-rotate'>{messages.good[Math.floor(Math.random() * messages.good.length)]}</div>
        } else {
            return null;
        }

    }
}

export default Tag;
