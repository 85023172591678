import Load from "react-loader-spinner";
import PropTypes from "prop-types";
import React from "react";
import messages from "./Variables/LoadingscreenMessages";

export const Loader = ({show, name}) => {
    const componentClasses = ["loader-container"];
    let message;

    if (show) {
        // let displayName = '';
        // if (typeof name === 'undefined') {
        //     displayName = 'collega';
        // } else {
        //     displayName = name;
        // }

        componentClasses.push("show");

        // if (displayName === "Klantenservice") {
        //     displayName = "allemaal";
        // }
        let d = new Date();
        let h = d.getHours()
        if(d.getDay() !== 5){
            if (h >= 12 && h <= 17) {
                message =
                    <div>
                        {messages.afternoon[Math.floor(Math.random() * messages.afternoon.length)]}
                    </div>;
            } else if (h >= 18 && h <= 20) {
                message =
                    <div>
                        {messages.evening[Math.floor(Math.random() * messages.evening.length)]}
                    </div>;
            } else if (h >= 21 && h <= 23) {
                message =
                    <div>
                        {messages.night[Math.floor(Math.random() * messages.night.length)]}
                    </div>

            } else if (h >= 0 && h <= 3) {
                message =
                    <div>
                        {messages.earlyMorning[Math.floor(Math.random() * messages.earlyMorning.length)]}
                    </div>

            } else if (h >= 4 && h <= 6) {
                message =
                    <div>
                        {messages.semiEarlyMorning[Math.floor(Math.random() * messages.semiEarlyMorning.length)]}
                    </div>

            } else if (h >= 7 && h <= 11) {
                message =
                    <div>
                        {messages.morning[Math.floor(Math.random() * messages.morning.length)]}
                    </div>;
            } else {
                message = <div>Hoe heb je dit voor elkaar gekregen?</div>;
            }
        } else {
            message =
                <div>
                    {messages.friday[Math.floor(Math.random() * messages.friday.length)]}
                </div>;
        }

    }

    return (
        <div className={componentClasses.join(" ")}>
            <div className="loader">
                <Load type="Grid" color="#FFCB01" height={100} width={100}/>
                <div className="message">{message}</div>
            </div>
        </div>
    );
};

Loader.propTypes = {
    show: PropTypes.bool.isRequired,
    name: PropTypes.string
};
export default Loader