import React, { Component } from "react";
import NumberBoxes from "../../common/NumberBoxes/NumberBoxes";
import { connect } from "react-redux";
class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalSolvedTickets: 0,
            rmaTotal: 0
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps && this.props.Tickets !== null) {
            let totalSolvedTickets = 0;

            let allTickets = this.props.Tickets.results;

            allTickets.forEach(item => {
                if (item.status === "solved") {
                    totalSolvedTickets++;
                }
            });
            let rmaTotal = this.getRMATotal();

            this.setState({
                rmaTotal,
                totalSolvedTickets
            });
        }
    }
    getRMATotal = () => {
        let rmaTotal = 0;
        if (this.props.RMA !== null && this.props.RMA !== []) {
            if (this.props.RMA !== undefined) {
                this.props.RMA.forEach(element => {
                    if (element.label === "Solved") {
                        rmaTotal += element.count;
                    }
                });

                return rmaTotal;
            }
        } else {
            return;
        }
    };

    render() {
        return (
            <div id="overview" className="block">
                <NumberBoxes
                    value1={this.state.rmaTotal}
                    label1={"Opgeloste RMA"}
                    value2={this.state.totalSolvedTickets}
                    label2={"Opgeloste Tickets"}
                />
            </div>
        );
    }
}
const mapStateToProps = (state = {}) => {
    if (
        typeof state.data !== "undefined" &&
        typeof state.data["event-zendesk-tickets"] !== "undefined" &&
        typeof state.data["event-rma-per-state-count"] !== "undefined"
    ) {
        return {
            Tickets: state.data["event-zendesk-tickets"],
            RMA: state.data["event-rma-per-state-count"]
        };
    }

    return {
        Tickets: null,
        RMA: []
    };
};
export default connect(mapStateToProps)(Main);
