import React, {Component} from "react";
import {connect} from "react-redux";

import Review from "./Review";

class Main extends Component {

    constructor(props) {
        const today = new Date();
        super(props);
        this.state = {
            author: "Mark Hendriks",
            rating: 10,
            recommended: true,
            title: "Jullie zijn geweldig",
            review: "Ik vind jullie allemaal geweldig!",
            date: today,
            city: "Zijtaart city",
            ReviewTimer: 0,
            reviewInterval: 60000,
            totalRating: 0,
            numberReviews: 0
        };
    }

    displayReview = () => {
        let {reviews} = this.props;

        if (reviews.reviews !== undefined && reviews.totals !== undefined) {
            let data =
                reviews.reviews[this.state.ReviewTimer];

            let totalRating = reviews.totals[0].rating;
            let numberReviews = reviews.totals[1].number_of_ratings;

            if (data !== undefined) {
                this.setState({
                    author: data.name,
                    rating: data.rating,
                    recommended: data.would_recommend,
                    title: data.review_one_line,
                    review: data.review,
                    date: data.created_at,
                    city: data.city,
                    totalRating,
                    numberReviews
                });
            } else {
                this.setState({
                    ReviewTimer: 0
                });
            }
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.displayReview();
        }

    }

    componentDidMount = () => {

        this.interval = setInterval(
            () => this.tick(),
            this.state.reviewInterval
        );


    };

    tick() {
        this.displayReview();

        this.setState(prevState => ({
            ReviewTimer: prevState.ReviewTimer + 1
        }));

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const {
            totalRating,
            numberReviews,
            author,
            rating,
            recommended,
            title,
            review,
            date,
            city,
            reviewInterval
        } = this.state;

        return (
            <div id="reviews" className="block">
                <Review
                    interval={reviewInterval}
                    numberReviews={numberReviews}
                    totalRating={totalRating}
                    rating={rating / 2}
                    title={title}
                    city={city}
                    review={review}
                    date={date}
                    author={author}
                    recommended={recommended}
                />
            </div>
        );
    }
}

const mapStateToProps = (state = {}) => {
    const result = {
        data: null,
        reviews: []
    };

    if (typeof state.data === "undefined") {
        return result;
    }

    if (typeof state.data["event-kiyoh-latest-reviews"] !== "undefined") {
        result.reviews = state.data["event-kiyoh-latest-reviews"];
    }

    return result;
};

export default connect(mapStateToProps)(Main);
