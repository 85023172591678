import React from "react";
import Header from "../../common/Headers/Header";
import Gauge from "../../common/Charts/Gauge";
import colors from "../../common/Variables/Colors";
import TimeToCutoff from "../TimeToCutoff";
import InfoBars from "../InfoBars";
function Done(props) {
  return (
    <>
      <div className="block">
        <Header
          number={props.rulesDone}
          color={"green"}
          label={"Done"}
          secondNumber={props.shipmentsDone}
          tooltip={"Regels"}
          tooptipSecondNumber={"Zendingen"}
        />
      </div>
      {typeof props.packingPerHour !== "undefined" && (
        <div className="block">
          <Gauge
            topColor={colors.greens[0]}
            progression={props.packingPerHour}
            goal={props.packingGoal}
            label="Packing per uur"
          />
        </div>
      )}
      <div className="block">
        <TimeToCutoff
            pakketCutOff={props.pakketCutOff}
            palletCutOff={props.palletCutOff}
        />
      </div>
      <div className="block">
        <InfoBars />
      </div>
    </>
  );
}

export default Done;
