import {
    GET_NAMESPACE_SETTINGS_SUCCESS,
    SET_NAMESPACE_SETTINGS_SUCCESS,
    GET_NAMESPACE_SETTINGS,
    SET_NAMESPACE_SETTINGS
} from "../actions/types";

const initialState = {
    loading: true
};
/**
 *
 * @param state
 * @param action
 * @returns {{}|initialState}
 *
 * {
 *     "warehouse-io": {
 *         packers: 1,
 *         pickers: 1
 *     },
 *     "customer-service-io": {
 *         ...
 *     },
 *     ...
 * }
 */
const settingsReducer = (state = initialState, action) => {
    const newState = {...state};
    switch (action.type) {
        case GET_NAMESPACE_SETTINGS:
        case SET_NAMESPACE_SETTINGS:
            newState.loading = true;
            return newState;
        case GET_NAMESPACE_SETTINGS_SUCCESS:
        case SET_NAMESPACE_SETTINGS_SUCCESS:
            newState[action.namespace] = action.settings;
            newState.loading = false;
            return newState;
        default:
            return state;
    }
}

export default settingsReducer;