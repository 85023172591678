import React, {Component} from "react";
import {getNamespaceSettings, openWebsocket} from "../actions/actions";
import {NAMESPACE_WAREHOUSE} from "../actions/types";
import {connect} from "react-redux";
import Loader from "./common/Loader";
import Header from "./common/Headers/Header";
import OrdersTable from "./common/IncomingOrders/OrdersTable";
import NumberBoxes from "./common/NumberBoxes/NumberBoxes";

class WarehouseInbound extends Component {
    componentDidMount() {
        this.connectSockets();
        this.props.getNamespaceSettings(NAMESPACE_WAREHOUSE);
    }

    connectSockets = () => {
        this.props.openWebsocket(NAMESPACE_WAREHOUSE);
    };

    render() {
        if (typeof this.props.data !== "undefined" && typeof this.props.orderData !== "undefined") {
            const {
                count_expected,
                count_processing,
                count_done,
                order_count_expected,
                order_count_processing,
                order_count_done
            } = this.props.data;
            return (
                <div className={"customer-service tv"}>
                    <div id="left" className="medium-column">
                        <div className="block">
                            <Header
                                number={count_expected}
                                color={"blue"}
                                label={"Verwacht"}
                                secondNumber={order_count_expected}
                                tooltip={"Regels"}
                                tooptipSecondNumber={"Zendingen"}
                            />
                        </div>
                        <div className="block">
                            <NumberBoxes
                                value1={this.props.orderData[0].count_expected}
                                label1={"Inkomande order regels vandaag"}
                                value2={this.props.orderData[0].order_count_expected}
                                label2={"Inkomende orders vandaag"}
                            />

                            <OrdersTable data={this.props.orderData}/>
                        </div>
                    </div>
                    <div id="center" className="medium-column">
                        <div className="block">
                            <Header
                                number={count_processing}
                                color={"yellow"}
                                label={"Ontvangen"}
                                secondNumber={order_count_processing}
                                tooltip={"Regels"}
                                tooptipSecondNumber={"Zendingen"}
                            />
                        </div>
                    </div>
                    <div id="right" className="medium-column">
                        <div className="block">
                            <Header
                                number={count_done}
                                color={"green"}
                                label={"Klaar"}
                                secondNumber={order_count_done}
                                tooltip={"Regels"}
                                tooptipSecondNumber={"Zendingen"}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Loader show={true}/>;
        }
    }
}

const mapStateToProps = (state = {}) => {
    const result = {active: {}, currentStatus: {}};

    if (typeof state.data["event-stocky-inbound-stats"] !== "undefined") {
        result.data = state.data["event-stocky-inbound-stats"];
    }
    if (typeof state.data["event-stocky-inbound-order-stats"] !== "undefined") {
        result.orderData = state.data["event-stocky-inbound-order-stats"];
    }
    return result;
};

export default connect(mapStateToProps, {
    openWebsocket,
    getNamespaceSettings,
})(WarehouseInbound);
