import React from "react";
import DoubleHeader from '../../common/Headers/DoubleHeader'

import Gauge from "../../common/Charts/Gauge";
import colors from "../../common/Variables/Colors";

function Doing(props) {
    return (
        <>
            <div className="block">
                <DoubleHeader
                    label1={"Picken"}
                    number1={props.rulesProcessing}
                    label2={"Packen"}
                    number2={props.rulesPicked}
                    color={"yellow"}
                    tooltip={"Regels"}
                    secondNumber1={props.shipmentsProcessing}
                    tooptipSecondNumber1={"Zendingen"}
                    secondNumber2={props.shipmentsPicked}
                    tooptipSecondNumber2={"Zendingen"}
                />
            </div>
            <div className="block">
                <Gauge
                    topColor={colors.yellows[0]}
                    progression={props.pickingPerHour}
                    goal={props.pickingGoal}
                    label="Picking per uur"
                />
            </div>
        </>
    );
}

export default Doing;
