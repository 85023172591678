export const GET_REVIEWS = "GET_REVIEWS";
export const OPEN_WEBSOCKET = 'OPEN_WEBSOCKET';
export const UPDATE_SOCKET_OBJECT = 'UPDATE_SOCKET_OBJECT';
export const RECEIVE_DATA = 'RECEIVE_DATA';
export const TV_MODE_ON = 'TV_MODE_ON';
export const TV_MODE_OFF = 'TV_MODE_OFF';
export const DECREMENT_PICKER = 'DECREMENT_PICKER';
export const INCREMENT_PICKER = 'INCREMENT_PICKER';
export const SET_KPI_PICKER = 'SET_KPI_PICKER';
export const DECREMENT_PACKER = 'DECREMENT_PACKER';
export const INCREMENT_PACKER = 'INCREMENT_PACKER';
export const SET_KPI_PACKER = 'SET_KPI_PACKER';

export const GET_NAMESPACE_SETTINGS = 'GET_NAMESPACE_SETTINGS';
export const GET_NAMESPACE_SETTINGS_SUCCESS = 'GET_NAMESPACE_SETTINGS_SUCCESS';
export const SET_NAMESPACE_SETTINGS = 'SET_NAMESPACE_SETTINGS';
export const SET_NAMESPACE_SETTINGS_SUCCESS = 'SET_NAMESPACE_SETTINGS_SUCCESS';

export const NAMESPACE_DEFAULT = 'default';
export const NAMESPACE_CUSTOMER_SERVICE = 'customer-service-io';
export const NAMESPACE_WAREHOUSE = 'warehouse-io';
export const NAMESPACE_RMA = 'rma-io';
export const NAMESPACE_CONTENT = 'content-io';