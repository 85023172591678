import {TV_MODE_ON, TV_MODE_OFF} from '../actions/types'
import {getCookie, setCookie} from "../cookies";


let cookieStats = false;
if (getCookie('tvMode') === 'true') {
    cookieStats = true;
}

const initialState = {
    tvMode: cookieStats
};

export default (state = initialState, action) => {
    
    const {type} = action; // can also use { type, data }
    switch (type) {
        case TV_MODE_ON:
            setCookie('tvMode', true);
            return {
                ...state,
                tvMode: true
            };
        case TV_MODE_OFF:
            setCookie('tvMode', false);
            return {
                ...state,
                tvMode: false
            };
        default:
            return state;
    }
}
