import React from "react";
import Header from "../../common/Headers/Header";
import { connect } from "react-redux";
import Prijsaanvragen from "./Prijsaanvragen";
import Bar from "../../common/Charts/Bar";
import colors from "../../common/Variables/Colors";
import { getMax } from "./Functions";
import { getAmountFromLabelInArray } from "../../common/Functions";

function Main(props) {
  let statusData = [
    {
      "Werkzaamheden": "",
    }
  ];

  if (props.tickets !== undefined) {
    statusData = [
      {
        "Werkzaamheden": "",
        "Te laat": props.tickets.te_laat,
        "Gevaar": props.tickets.gevaar,
        "Op tijd": props.tickets.op_tijd,
        "Open": props.tickets.open,
        "Opvolgen": props.tickets.opvolgen,
      },
    ];
  }

  let priceRequests = getAmountFromLabelInArray(
    "priceMatchOpenCount",
    props.events
  );
  let max = getMax();
  return (
    <div id="tickets" className="block">
      <Prijsaanvragen count={priceRequests} />
      <Header
        label={"Aantal tickets"}
        color={"green"}
        number={props.tickets.totaal}
      />
      <div className="s-tickets">
        <Bar
          max={max}
          label="Status"
          items={["test"]}
          total={props.tickets.totaal}
          color={colors.greens}
          data={statusData}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state = {}) => {
  let result = { tickets: [], priceRequests: 0 };
  if (typeof state.data["event-robin-tickets"] !== "undefined") {
    result.tickets = state.data["event-robin-tickets"][0];
  }
  if (typeof state.data["event-current-stats"] !== "undefined") {
    result.events = state.data["event-current-stats"];
  }
  result.tvMode = state.app.tvMode;
  return result;
};
export default connect(mapStateToProps)(Main);
