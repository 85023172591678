import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { fadeIn, slideInLeft, slideInRight } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { connect } from "react-redux";
import Cross from "../../../img/cross.png";
import MenuSettings from "./MenuSettings/MenuSettings";

const styles = {
  fadeIn: {
    animation: "x 1s",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
  slideInLeft: {
    animation: "x 1s",
    animationName: Radium.keyframes(slideInLeft, "slideInLeft"),
  },
  slideInRight: {
    animation: "x 1s",
    animationName: Radium.keyframes(slideInRight, "slideInRight"),
  },
};

class ProfilePopup extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      this.state.show
    ) {
      this.setState({ show: false });
    }
  }

  showPopup = () => {
    if (!this.state.show) {
      this.setState({
        show: true,
      });
    }
  };
  redirectToTarget = (target) => {
    window.location.href = target;
  };
  show = () => {
    let fourKScreen = false;
    if (window.innerWidth > 3000) {
      fourKScreen = true;
    }

    let { name, email } = this.props;

    if (this.state.show) {
      return (
        <div className="popup">
          <div className="popup__close">
            <img
              src={Cross}
              onClick={() => this.setState({ show: false })}
              alt="close button"
            />
          </div>
          <div>
            <div className="popup__header">
              <StyleRoot>
                <img
                  className="image"
                  style={styles.slideInRight}
                  src={this.props.img}
                  alt="logo"
                />
              </StyleRoot>
              <div>
                <div className="popup__name">{name}</div>
                <div className="popup__email">{email}</div>
              </div>
            </div>
          </div>
          <div className="popup__dashboards">
            <div
              className={
                window.location.hash === "#/customer-service"
                  ? "popup__dashboard__active"
                  : null
              }
              onClick={() => {
                window.location.href = "/#/customer-service";
              }}
            >
              <div className="popup__dashboard">Klantenservice</div>
            </div>
            <div
              className={
                window.location.hash === "#/content"
                  ? "popup__dashboard__active"
                  : null
              }
              onClick={() => {
                window.location.href = "/#/content";
              }}
            >
              <div className="popup__dashboard">Content & Marketing</div>
            </div>
            <div
              className={
                window.location.hash === "#/warehouse"
                  ? "popup__dashboard__active"
                  : null
              }
              onClick={() => this.redirectToTarget("/#/warehouse")}
            >
              <div className="popup__dashboard ">Magazijn</div>
            </div>
            <div className="popup__dashboard__settings">
              <div
                onClick={() => this.redirectToTarget("/#/warehouse/settings")}
                className={
                  window.location.hash === "#/warehouse/settings"
                    ? "c-settings animate-spin"
                    : "c-settings"
                }
              />
            </div>
            <div
              className={
                window.location.hash === "#/warehouse/pakket"
                  ? "popup__dashboard__active"
                  : null
              }
              onClick={() => {
                window.location.href = "/#/warehouse/pakket";
              }}
            >
              <div className="popup__dashboard">Magazijn - Pakket</div>
            </div>
            <div
              className={
                window.location.hash === "#/warehouse/pallet"
                  ? "popup__dashboard__active"
                  : null
              }
              onClick={() => {
                window.location.href = "/#/warehouse/pallet";
              }}
            >
              <div className="popup__dashboard">Magazijn - Pallet</div>
            </div>
            <div
              className={
                window.location.hash === "#/warehouse/inbound"
                  ? "popup__dashboard__active"
                  : null
              }
              onClick={() => {
                window.location.href = "/#/warehouse/inbound";
              }}
            >
              <div className="popup__dashboard">Magazijn - Inbound</div>
            </div>
          </div>
          <div>{!fourKScreen ? <MenuSettings /> : null}</div>
          <div onClick={this.props.logout} className="popup__logout">
            <div>Logout</div>
          </div>
        </div>
      );
    } else {
      return (
        <StyleRoot>
          <div onClick={this.showPopup} style={styles.slideInLeft}>
            <img className="image" alt="open profile" src={this.props.img} />
          </div>
        </StyleRoot>
      );
    }
  };

  render() {
    return (
      <StyleRoot>
        <div
          className={
            "popup " + (this.state.show ? "popup__show" : "popup__hidden")
          }
          ref={this.setWrapperRef}
          style={this.state.show ? styles.fadeIn : null}
        >
          {this.show()}
        </div>
      </StyleRoot>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default withRouter(connect(mapStateToProps)(ProfilePopup));
