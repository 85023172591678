import React from 'react';
import NumberBoxes from "../common/NumberBoxes/NumberBoxes";
import {connect} from "react-redux";

import {getMonthDutch} from "../common/Functions";

function Achievements(props) {

    return (
        <div className={'block'}>
            <NumberBoxes
                label1={'Top order ' + getMonthDutch()}
                value1={Math.round(props.bestOrderLast30Days)}
                isMoney1={true}
                label2={'Ander leuk feitje'}/>


        </div>
    );
}


const mapStateToProps = (state = {}) => {
    const result = {
        latestNewProductSales: [],
        bestOrderLast30Days: 0
    };
    if (
        typeof state.data !== "undefined" &&
        typeof state.data["event-current-stats"] !== "undefined"
    ) {
        let currentStats = {};

        state.data["event-current-stats"].forEach(function (
            current_stat_response
        ) {
            currentStats[current_stat_response.label] = current_stat_response.result;
        });

        result.bestOrderLast30Days = currentStats.bestOrderLast30Days;
    }

    if (
        typeof state.data !== "undefined" &&
        typeof state.data["event-latest-new-product-sales"] !== "undefined"
    ) {

        result.latestNewProductSales = state.data["event-latest-new-product-sales"];
    }

    return result;
};
export default connect(mapStateToProps)(Achievements);