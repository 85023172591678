import {
    GET_NAMESPACE_SETTINGS,
    // GET_NAMESPACE_SETTINGS_SUCCESS,
    SET_NAMESPACE_SETTINGS,
    // SET_NAMESPACE_SETTINGS_SUCCESS
} from "../actions/types";

import fetch from "node-fetch";
import axios from 'axios';
import {getNamespaceSettingsSuccess, setNamespaceSettingsSuccess} from "../actions/actions";

const settingsMiddleware = store => next => action => {
    // var backendUrl = window.location.protocol+'//socket.'+window.location.hostname + ':3001';
    var backendUrl = window.location.protocol+'//socket.'+window.location.hostname;

    if (action.type === GET_NAMESPACE_SETTINGS) {
        fetch(backendUrl+'/settings/'+action.namespace+'?hostname='+window.location.hostname)
            .then(res => res.json())
            .then(json => {
                store.dispatch(getNamespaceSettingsSuccess(action.namespace, json));
            })
            .catch(err => console.error(err))
        ;
    }

    if (action.type === SET_NAMESPACE_SETTINGS) {
        axios.post(backendUrl+'/settings/'+action.namespace+'?hostname='+window.location.hostname, action.settings)
            .then(json => {
                store.dispatch(setNamespaceSettingsSuccess(action.namespace, json));
            })
            .catch(err => console.error(err))
        ;
    }

    next(action);
}

export default settingsMiddleware;
