const settingsVars = {
    minFinishedToShowProgress: 1, // You guessed it, the minimum requirement to show the progress on the warehouse page.
    minToDoToShowProgress: 4, // Minimum what has to be on to do to display progression
    averageOrderSize: 1.2, //How many articles are in 1 order on average.
    warehousePerformance: {
        bad: 40, // les then x % is bad
        okay: 90, // Between bad and x % is okay
        good: 100, // Between okay and x % is good
        incredible: 110 // More then x % is incredible
    },
    timeBetweenSlider: 60000, // The time it takes to go to the next slide on for example content and marketing dashboard
    warehouseMinForConfetti: 5,
    tagOrdersLastHour: {
        good: 50,
        incredible: 100
    } // How many orders are needed to show a tag at the orders last hour
};

export const confettiSettings = {
    recycle: true, // Loop confetti
    pieces: 1, // 0 / 2000
    wind: 0, // -0.5 / 0.5,
    gravity: 0.05, // 0 / 1
    opacity: 1 // 0 / 1
};
export default settingsVars;
