let messages = {};

//Make sure messages are no longer then 10 characters including spacebars!
messages.earlyMorning = ["Sorry, ik sliep", "Zou je niet nog wat proberen te slapen", "Ik snap dat het leuk is om naar het dashboard te kijken, maar slaap is belangrijk", "Nachtje aan het doorhalen?"]; //0-3
messages.semiEarlyMorning = ["Goeie, vroege, ochtend", "....*snurk* ....*snurk*", "Gegroed, aardmens", "Mafkees! Draag nog ff om!", "Als je nu kijkt ben je niet goed..."]; //4-6
messages.morning = ["Goeiemorgen", "Good morning", "Guten morgen", "Inkakken is bijpakken", "Koffierouletteje?", "Bakkie pleur doet wonderen", "Goein ochtend", "Zelland"] //7-11
messages.afternoon = ["Goedemiddag", "Goeiendag", "Goeiendagskottel", "Moet jij iets van de pomp?"]; //12-17
messages.evening = ["Goedeavond", "Goeienavond", "Good evening", "Welkom terug", "Laatste loodjes?"]; //18-20
messages.night = ["Hele goede avond", "Lange dagen?", "Is het geen bedtijd?", "Hand en span diensten", "Klaas vaak al langs geweest?"]; //21-23
messages.friday = ["Bijna weekend", "......hik", "Lekker borrelen dadelijk?", "....Wat een week", "Zet de frituur maar vast aan", "Wie bakt de snacks?", "Haaa biiieerrr", "Zeg vast tegen Sander dat hij de frituur aanjaagt"]; //fridays
export default messages;