import React from 'react';
import DigitRoll from "digit-roll-react";

/*
Expected props:

color={'string'} // String with the color you want. You can either use green, blue, yellow, wide-blue or green2, or add a new one by following the steps on the bottom of this comment box
label1={'string'} // Label above the header
number1={n} // Value of what you want in your header
label2={'string'} // Label above the header
number2={n} // Value of what you want in your header

Steps to adding new colors:
1. Make a new, likewise header as the other ones, for example by opening one of the existing headers in Photoshop/paint.net and shape them to however you like.
2. Add this photo to the following repository: '\dashboard-project\frontend\src\img' with a name like: 'markers-full-blue-2.png'
3. Go to the dna.scss, and paste the following code in there with your own variable names
#yourColor {
  background-image: url("../../img/yourNewImageName");
}
4. Use the component as explained above, with as color how you called the #yourColor in dna.scss
 */
function Header(props) {
    return (
        <div className="o-header" id={props.color}>

            <div className='o-header__number__container'>
                <div className="">
                    <div className="o-header__label">
                        <div className="c-label">{props.label1}</div>
                    </div>
                    <div className="o-header__number">
                        <div className="c-roller_number">
                            {props.isMoney1 ? "€" : null}

                            <DigitRoll
                                num={isNaN(props.number1) ? 0 : props.number1}
                                length={isNaN(props.number1) ? 0 : props.number1.length}
                                divider="."
                                delay={3}
                            />
                        </div>
                    </div>

                    {typeof props.secondNumber1 !== "undefined" ? (
                        <div
                            data-tip={props.tooptipSecondNumber1}
                            className="o-header__second_number"
                        >
                            <DigitRoll
                                num={isNaN(props.secondNumber1) ? 0 : parseInt(props.secondNumber1)}
                                length={
                                    isNaN(props.secondNumber1)
                                        ? 0
                                        : parseInt(props.secondNumber1).length
                                }
                                divider="."
                                delay={3}
                            />
                        </div>
                    ) : null}
                </div>
                <div>
                    <div className="o-header__label">
                        <div className="c-label">{props.label2}</div>
                    </div>
                    <div className="o-header__number">
                        <div className="c-roller_number">
                            {props.isMoney2 ? "€" : null}
                            <DigitRoll
                                num={isNaN(props.number2) ? 0 : props.number2}
                                length={isNaN(props.number2) ? 0 : props.number2.length}
                                divider="."
                                delay={3}
                            />
                        </div>
                    </div>
                    {typeof props.secondNumber2 !== "undefined" ? (
                        <div
                            data-tip={props.tooptipSecondNumber2}
                            className="o-header__second_number"
                        >
                            <DigitRoll
                                num={isNaN(props.secondNumber2) ? 0 : parseInt(props.secondNumber2)}
                                length={
                                    isNaN(props.secondNumber2)
                                        ? 0
                                        : parseInt(props.secondNumber2).length
                                }
                                divider="."
                                delay={3}
                            />
                        </div>
                    ) : null}
                </div>
            </div>

        </div>
    );
}

export default Header;

