import React, {Component} from "react";
import Bell from "../../../img/bell.png";
import Radium, {StyleRoot} from "radium";
import {slideInLeft, slideOutLeft} from "react-animations";

const styles = {
    slideInLeft: {
        animation: "x 1s",
        animationName: Radium.keyframes(slideInLeft, "slideInLeft")
    },
    slideOutLeft: {
        animation: "x 1s",
        animationName: Radium.keyframes(slideOutLeft, "slideOutLeft")
    }
};
export default class Prijsaanvragen extends Component {
    render() {
        if (this.props.count > 0) {
            return (
                <StyleRoot>
                    <div
                        style={styles.slideInLeft}
                        className="o-notification__container"
                    >
                        <div className="o-notification__container__image"><img className="c-bell" src={Bell}
                                                                               alt="Bell icon"></img></div>
                        <div className='o-notification__container__text'>
                        <div className="text">
                            {this.props.count}{" "}
                            {this.props.count === 1
                                ? "Prijsaanvraag"
                                : "Prijsaanvragen"}
                        </div>
                        </div>
                    </div>
                </StyleRoot>
            );
        } else {
            return null;
        }
    }
}
