import React from 'react';
import NumberBoxes from "../../common/NumberBoxes/NumberBoxes";
import {connect} from "react-redux";
import {openWebsocket} from "../../../actions/actions";
import Loader from "../../common/Loader";


function Summary(props) {
    if (typeof props.new !== "undefined") {
        return <div className='block'>
            <NumberBoxes value1={props.new.count}
                         label1={"Nieuw"}
                         value2={props.customerService.count}
                         label2={"Klantenservice"}
            />
            <NumberBoxes value1={props.warehouse.count}
                         label1={"Magazijn"}
                         value2={props.customer.count}
                         label2={"Klant"}
            />
            <NumberBoxes value1={props.repair.count}
                         label1={"Reparatie"}
                         value2={props.solved.count}
                         label2={"Opgelost"}
            />
            <NumberBoxes value1={props.process.count}
                             label1={"Process"}
                             value2={0}
                             label2={"Nothing"}
        />

        </div>;
    } else {
        return <Loader show={true}/>
    }
}

const mapStateToProps = (state) => {
    let result = {};
    if (typeof state.data["event-rma-per-state-count"] !== "undefined") {
        result.new = state.data["event-rma-per-state-count"][0];
        result.customerService = state.data["event-rma-per-state-count"][1];
        result.warehouse = state.data["event-rma-per-state-count"][2];
        result.customer = state.data["event-rma-per-state-count"][3];
        result.repair = state.data["event-rma-per-state-count"][4];
        result.solved = state.data["event-rma-per-state-count"][5];
        result.process = state.data["event-rma-per-state-count"][6];
    }
    return result;
};
export default connect(mapStateToProps, {openWebsocket})(Summary);
