// import {RECEIVE_DATA} from "../actions/types";

// const handleDummyMessage = (eventName, result, store) => {
//     store.dispatch({
//         type: RECEIVE_DATA,
//         payload: {
//             event: eventName,
//             data: result
//         }
//     });
// };
//
// let currentReviewJson = [];

const dispatchDummyData = store => {
    // var backendUrl = window.location.protocol+'//socket.'+window.location.hostname;
    // fetch(backendUrl+'/static/DummyData/reviews.json?hostname='+window.location.hostname)
    //     .then(response => response.json())
    //     .then((jsonData) => {
    //         if (currentReviewJson.toString() !== jsonData.toString()) {
    //             currentReviewJson = jsonData;
    //             handleDummyMessage("event-reviews", jsonData, store);
    //         }
    //     })
    //     .catch((error) => {
    //         console.error(error);
    //     })
};

let initialized = false;

const dummyMiddleware = store => next => action => {
    if (!initialized) {
        initialized = true;
        setInterval(function () {
            dispatchDummyData(store);
        }, 5000);
    }
    next(action);
};

export default dummyMiddleware;
