import React, { useState } from "react";
import { getCutoffTime } from "./CutoffFunctions";

function TimeToCutoff(props) {
  const [count, triggerChange] = useState(0);
  setInterval(() => triggerChange(count + 1), 60000);
  const ttcPostNL = getCutoffTime(props.pakketCutOff);
  const ttcPallets = getCutoffTime(props.palletCutOff);

  let ttcPalletsDue = false;
  let ttcPostNLDue = false;

  if (ttcPallets.minutes < 0 && ttcPallets.hours < 0) {
    ttcPalletsDue = true;
  }
  if (ttcPostNL.minutes < 0 && ttcPostNL.hours < 0) {
    ttcPostNLDue = true;
  }
  const postNL = (
    <div>
      Tijd tot cutoff PostNL:
      {ttcPostNL.hours > 0 ? (
        <span>
          <span className="blue typpo"> {ttcPostNL.hours} </span> uur en{" "}
        </span>
      ) : null}
      {ttcPostNL.minutes === 1 ? (
        <span>
          <span className="blue typpo"> {ttcPostNL.minutes}</span> minuut
        </span>
      ) : (
        <span>
          <span className="blue typpo"> {ttcPostNL.minutes}</span> minuten
        </span>
      )}
    </div>
  );

  const pallets = (
    <div>
      Tijd tot cutoff Pallets:
      {ttcPallets.hours > 0 ? (
        <span>
          <span className="blue typpo"> {ttcPallets.hours} </span> uur en{" "}
        </span>
      ) : null}
      {ttcPallets.minutes === 1 ? (
        <span>
          <span className="blue typpo"> {ttcPallets.minutes}</span> minuut
        </span>
      ) : (
        <span>
          <span className="blue typpo"> {ttcPallets.minutes}</span> minuten
        </span>
      )}
    </div>
  );

  if (!ttcPostNLDue && !ttcPalletsDue) {
    return (
      <div className="block__no_margin s-visitors-container">
        {ttcPostNLDue ? <div>PostNL cutoff is geweest</div> : postNL}
        {ttcPalletsDue ? <div>Pallets cutoff is geweest</div> : pallets}
      </div>
    );
  } else {
    return (
      <div className="block__no_margin s-visitors-container">
        Cutoffs zijn geweest, morgen weer een dag!
      </div>
    );
  }
}

export default TimeToCutoff;
