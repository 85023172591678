import React from 'react';

function HorizontalLegendItem(props) {
    return (
        <div className="o-horizontal-legend__item">
            <div className="c-legend-item-horizontal c-label">
                <div
                    className="color"
                    style={{backgroundColor: props.color}}
                />
                {props.item}
            </div>
        </div>
    );
}

export default HorizontalLegendItem;