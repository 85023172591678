import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getNamespaceSettings, setNamespaceSettings} from "../../actions/actions";
import Loader from '../common/Loader';
import {bindActionCreators} from "redux";
import BackArrow from '../common/BackArrow'
import {NAMESPACE_WAREHOUSE,} from "../../actions/types";
import moment from "moment";

// this.props.setNamespaceSettings({pakketPickers: this.state.pakketPickers, pakketPackers: this.state.pakketPackers})

function ActiveEmployees(props) {

    const pakketCutOffDate = new Date();
    pakketCutOffDate.setHours(21);
    pakketCutOffDate.setMinutes(0, 0, 0);
    const palletCutOffDate = new Date();
    pakketCutOffDate.setHours(16);
    pakketCutOffDate.setMinutes(0, 0, 0);
    const [pakketPickers, setPakketPickers] = useState(1);
    const [pakketPackers, setPakketPackers] = useState(1);
    const [pakketPickGoal, setPakketPickGoal] = useState(60);
    const [pakketPackGoal, setPakketPackGoal] = useState(40);
    const [pakketCutOff, setPakketCutOff] = useState(pakketCutOffDate.valueOf());
    const [palletPickers, setPalletPickers] = useState(1);
    const [palletPackers, setPalletPackers] = useState(1);
    const [palletPickGoal, setPalletPickGoal] = useState(60);
    const [palletPackGoal, setPalletPackGoal] = useState(40);
    const [palletCutOff, setPalletCutOff] = useState(palletCutOffDate.valueOf());
    const [initData, setInitData] = useState(false);
    const [namespaceCalled, setNamespaceCalled] = useState(false);

    useEffect(() => {
        if (!namespaceCalled) {
            props.getNamespaceSettings(NAMESPACE_WAREHOUSE);
            setNamespaceCalled(true);
        }

    }, [namespaceCalled, props]);

    if (props.loading) {
        return <Loader show={props.loading}/>
    } else {
        if (!initData) {
            setPakketPickers(props.settings.pakketPickers);
            setPakketPackers(props.settings.pakketPackers);
            setPakketPickGoal(props.settings.pakketPickGoal)
            setPakketPackGoal(props.settings.pakketPackGoal);
            setPakketCutOff(props.settings.pakketCutOff);
            setPalletPickers(props.settings.palletPickers);
            setPalletPackers(props.settings.palletPackers);
            setPalletPickGoal(props.settings.palletPickGoal);
            setPalletPackGoal(props.settings.palletPackGoal);
            setPalletCutOff(props.settings.palletCutOff);

            setInitData(true);
        }

        /*
        component ->
         */
        return (
            <div>
                <div>


                </div>
                <BackArrow name='magazijn dashboard' link='/#/warehouse/'/>
                <form
                    onSubmit={e => {
                        e.preventDefault();

                        props.setNamespaceSettings((NAMESPACE_WAREHOUSE), {
                            pakketPickers: pakketPickers,
                            pakketPackers: pakketPackers,
                            pakketPickGoal: pakketPickGoal,
                            pakketPackGoal: pakketPackGoal,
                            pakketCutOff: pakketCutOff.valueOf(),
                            palletPickers: palletPickers,
                            palletPackers: palletPackers,
                            palletPickGoal: palletPickGoal,
                            palletPackGoal: palletPackGoal,
                            palletCutOff: palletCutOff.valueOf(),
                        });

                    }}
                >
                    <div className='o-active-employees__container'>
                        <div className='o-active-employees__title'>
                            Magazijn instellingen
                        </div>
                        <div className='o-active-employees__buttons'>
                            <div className="c-min red"
                                 onClick={() => setPakketPickers(pakketPickers === 1 ? 1 : pakketPickers - 1)}>-
                            </div>
                            <div className='o-active-employee__employees'>Pakket Pickers: {pakketPickers}</div>
                            <div className="c-plus green" onClick={() => setPakketPickers(pakketPickers + 1)}>+</div>
                        </div>
                        <div className='o-active-employees__buttons'>
                            <div className="c-min red"
                                 onClick={() => setPakketPickGoal(pakketPickGoal === 1 ? 1 : pakketPickGoal - 1)}>-
                            </div>
                            <div className='o-active-employee__employees'>Pakket Pick Doel: {pakketPickGoal}</div>
                            <div className="c-plus green" onClick={() => setPakketPickGoal(pakketPickGoal + 1)}>+</div>
                        </div>
                        <div className='o-active-employees__buttons'>
                            <div className="c-min red"
                                 onClick={() => setPakketPackers(pakketPackers === 1 ? 1 : pakketPackers - 1)}>-
                            </div>
                            <div className='o-active-employee__employees'> Pakket Packers: {pakketPackers}</div>
                            <div className="c-plus green" onClick={() => setPakketPackers(pakketPackers + 1)}>+</div>
                        </div>
                        <div className='o-active-employees__buttons'>
                            <div className="c-min red"
                                 onClick={() => setPakketPackGoal(pakketPackGoal === 1 ? 1 : pakketPackGoal - 1)}>-
                            </div>
                            <div className='o-active-employee__employees'>Pakket Pack Doel: {pakketPackGoal}</div>
                            <div className="c-plus green" onClick={() => setPakketPackGoal(pakketPackGoal + 1)}>+</div>
                        </div>
                        <div className='o-active-employees__buttons'>
                            <div className="c-min red"
                                 onClick={() => setPakketCutOff((new Date(pakketCutOff - (30*60*1000))).valueOf())}>-
                            </div>
                            <div className='o-active-employee__employees'>Pakket CutOff: {moment(pakketCutOff).format('HH:mm')}</div>
                            <div className="c-plus green"
                                 onClick={() => setPakketCutOff((new Date(pakketCutOff + (30*60*1000))).valueOf())}>+
                            </div>
                        </div>
                        <div className='o-active-employees__buttons'>
                            <div className="c-min red"
                                 onClick={() => setPalletPickers(palletPickers === 1 ? 1 : palletPickers - 1)}>-
                            </div>
                            <div className='o-active-employee__employees'>Pallet Pickers: {palletPickers}</div>
                            <div className="c-plus green" onClick={() => setPalletPickers(palletPickers + 1)}>+</div>
                        </div>
                        <div className='o-active-employees__buttons'>
                            <div className="c-min red"
                                 onClick={() => setPalletPackers(palletPackers === 1 ? 1 : palletPackers - 1)}>-
                            </div>
                            <div className='o-active-employee__employees'>Pallet Packers: {palletPackers}</div>
                            <div className="c-plus green" onClick={() => setPalletPackers(palletPackers + 1)}>+</div>
                        </div>
                        <div className='o-active-employees__buttons'>
                            <div className="c-min red"
                                 onClick={() => setPalletPickGoal(palletPickGoal === 1 ? 1 : palletPickGoal - 1)}>-
                            </div>
                            <div className='o-active-employee__employees'>Pallet Pick Doel: {palletPickGoal}</div>
                            <div className="c-plus green" onClick={() => setPalletPickGoal(palletPickGoal + 1)}>+</div>
                        </div>
                        <div className='o-active-employees__buttons'>
                            <div className="c-min red"
                                 onClick={() => setPalletPackGoal(palletPackGoal === 1 ? 1 : palletPackGoal - 1)}>-
                            </div>
                            <div className='o-active-employee__employees'>Pallet Pack Doel: {palletPackGoal}</div>
                            <div className="c-plus green" onClick={() => setPalletPackGoal(palletPackGoal + 1)}>+</div>
                        </div>
                        <div className='o-active-employees__buttons'>
                            <div className="c-min red"
                                 onClick={() => setPalletCutOff((new Date(palletCutOff - (30*60*1000))).valueOf())}>-
                            </div>
                            <div className='o-active-employee__employees'>Pallet CutOff: {moment(palletCutOff).format('HH:mm')}</div>
                            <div className="c-plus green"
                                 onClick={() => setPalletCutOff((new Date(palletCutOff + (30*60*1000))).valueOf())}>+
                            </div>
                        </div>
                        <button className='c-3dbutton green finish' type="submit">OPSLAAN</button>
                    </div>
                </form>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        settings: getSettings(state),
        loading: state.settings.loading
    }
};

export const getSettings = (state) => Object.assign(
    {},
    {
        pakketPickers: 1,
        pakketPackers: 1,
        pakketPickGoal: 60,
        pakketPackGoal: 40,
        pakketCutOff: 1588186800000,
        palletPickers: 1,
        palletPackers: 1,
        palletPickGoal: 60,
        palletPackGoal: 40,
        palletCutOff: 1588168800000,
    },
    state.settings && state.settings[NAMESPACE_WAREHOUSE] !== 'undefined' ? state.settings[NAMESPACE_WAREHOUSE] : {}
    );

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setNamespaceSettings,
            getNamespaceSettings
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ActiveEmployees);
