import React from "react";
import { ResponsivePie } from "@nivo/pie";

/*
Expected props:
progression={n}
goal={n}

optional:
simple={true} // Removes legend
percentage={true} (displays in percentages instead of just numbers)
label='string'
 */

function Gauge(props) {
  let data = [];
  const { progression, goal } = props;

  data.push({
    value: Math.round((progression / goal) * 100),
    id: "progression",
    label: "progression",
  });
  data.push({
    value: Math.round(data[0].value >= 0 ? 100 - data[0].value : data[0].value),
    id: "filler",
    label: "",
  });
  let format = (v) => `${v}%`;

  let colors = ["#F3575E", "#efefef"];

  if (data[0].value > 95) {
    colors = ["#12AC74", "#efefef"];
  } else if (data[0].value > 70) {
    colors = ["#12AC74", "#efefef"];
  } else if (data[0].value > 50) {
    colors = ["#B6EE56", "#efefef"];
  } else if (data[0].value > 30) {
    colors = ["#FFA500", "#efefef"];
  }

  if (data.length <= 2) {
    return (
      <>
        <div className="o-gauge">
          <div className="o-gauge__header">
            <p className="c-label">{props.label}</p>
          </div>

          <ResponsivePie
            data={data}
            fit={true}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            innerRadius={0.6}
            colors={colors}
            animate={true}
            motionStiffness={90}
            startAngle={-90}
            endAngle={90}
            motionDamping={15}
            enableRadialLabels={false}
            enableSlicesLabels={false}
            labelFormat={format}
            tooltipFormat={format}
          />
          <div className="o-gauge__progression">
            <p className="o-gauge__progression__numbers">
              {progression}/{goal}
            </p>
            <p className="o-gauge__progression__percentage">{data[0].value}%</p>
          </div>
        </div>
      </>
    );
  } else {
    return <div>Error</div>;
  }
}
export default Gauge;
