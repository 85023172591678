import React from 'react';
import {ResponsiveBar} from "@nivo/bar";
import HorizontalLegend from "./HorizontalLegend/HorizontalLegend";
import {getKeys} from "../Functions";

/*
Expected props:
data={data} //Look a little down to see a example

Optional props:
label='string' //Label above chart
items={['New', 'Open', 'Awaiting']} // keys of the data you send you want to use // You can also use the getKeys() function from ../common/Functions.js. And use
indexBy='string' // What you want to compare in the chart
dataLabels={['New', 'Open', 'Awaiting']} // Which items you want to display as labels
total={n} // Total amount of work that is left to set a 'max'
color={colors.greens} Which color you want to see used

Data example:
 const data = [{Workload: '', CustomerService: 3, New: 5}
 */

function Bar(props) {
    if (typeof props.data !== 'undefined') {
        let keys = getKeys(props.data);
        return (
            <div>
                <div>
                    <HorizontalLegend
                        label={props.label}
                        items={keys.keys}
                        colors={props.color}
                    />
                </div>
                <div className="o-bar__container">
                    <div className="o-bar__chart-number">0</div>
                    <div className="o-bar">
                        <ResponsiveBar
                            data={props.data}
                            keys={keys.keys}
                            maxValue={props.max}
                            labelSkipWidth={20}
                            layout={"horizontal"}
                            indexBy={keys.indexBy}
                            margin={{top: 0, right: 0, bottom: 0, left: 0}}
                            padding={0}
                            colors={props.color}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={null}
                            axisLeft={null}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            labelTextColor={"#ffffff"}
                        />
                    </div>
                    <div
                        className={"o-bar__chart-number " + getMaxClass(props.total, props.max)}
                    >
                        {props.max}
                    </div>
                </div>
            </div>
        );
    } else {
        //TO:DO Skeleton loader
        return <div/>
    }

}

function getMaxClass(total, max) {
    let i25 = (max / 100) * 125;
    let i50 = (max / 100) * 155;
    if (total > max) {
        if (total < i25) {
            return "orange";
        } else if (total < i50) {
            return "red-light";
        } else {
            return "alert";
        }
    } else {
        return "black";
    }
}

export default Bar;
