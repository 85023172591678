import React from "react";
import Countdown from "react-countdown";

function Countdown2(props) {
  const timeOfCutoff = new Date(props.cutoff);
  const dateTodayWithTimeOfCutoff = new Date();
  dateTodayWithTimeOfCutoff.setHours(timeOfCutoff.getHours());
  dateTodayWithTimeOfCutoff.setMinutes(timeOfCutoff.getMinutes());

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <p>Tijd zit erop!</p>;
    } else {
      // Render a countdown
      return (
        <span>
          {hours < 10 ? "0" + hours : hours}:
          {minutes < 10 ? "0" + minutes : minutes}:
          {seconds < 10 ? "0" + seconds : seconds}
        </span>
      );
    }
  };

  return (
    <div className="block clock">
      <div className="o-gauge__header">
        <p className="c-label">{props.headerText}</p>
      </div>
      <Countdown renderer={renderer} date={dateTodayWithTimeOfCutoff} />
    </div>
  );
}
export default Countdown2;
