import React from "react";
import DigitRoll from "digit-roll-react";
import ReactTooltip from "react-tooltip";

/*
Expected props:
number={n} // Value of what you want in your header
color={'string'} // String with the color you want. You can either use green, blue, yellow, wide-blue or green2, or add a new one by following the steps on the bottom of this comment box
label={'string'} // Label above the header

Steps to adding new colors:
1. Make a new, likewise header as the other ones, for example by opening one of the existing headers in Photoshop/paint.net and shape them to however you like.
2. Add this photo to the following repository: '\dashboard-project\frontend\src\img' with a name like: 'markers-full-blue-2.png'
3. Go to the dna.scss, and paste the following code in there with your own variable names
#yourColor {
  background-image: url("../../img/yourNewImageName");
}
4. Use the component as explained above, with as color how you called the #yourColor in dna.scss
 */
function Header(props) {
  return (
    <div className="o-header" id={props.color}>
      <div className="o-header__label">
        <div className="c-label">{props.label}</div>
      </div>

      <div data-tip={props.tooltip} className="o-header__number">
        <div className="c-roller_number">
          {props.isMoney ? "€" : null}

          <DigitRoll
            num={isNaN(props.number) ? 0 : parseInt(props.number)}
            length={isNaN(props.number) ? 0 : parseInt(props.number).length}
            divider="."
            delay={3}
          />
        </div>
      </div>

      {typeof props.secondNumber !== "undefined" ? (
        <div
          data-tip={props.tooptipSecondNumber}
          className="o-header__second_number"
        >
          <DigitRoll
            num={isNaN(props.secondNumber) ? 0 : parseInt(props.secondNumber)}
            length={
              isNaN(props.secondNumber)
                ? 0
                : parseInt(props.secondNumber).length
            }
            divider="."
            delay={3}
          />
        </div>
      ) : null}

      <ReactTooltip />
    </div>
  );
}

export default Header;
